import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Tooltip,
  DatePicker,
  InputNumber,
  Row,
  Col,
  message,
  Tag,
  notification,
  Radio,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  CloseOutlined,
  SearchOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { render } from "@testing-library/react";
import Draggable from "react-draggable";

import OrderForm from "./OrderForm";
import formatDate from "../../Components/formatDate";

import {
  CreateRecord,
  ReadRecord,
  UpdateRecord,
} from "../../Service/API/ApiService";

const Order = () => {
  const [orders, setOrderDetails] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [editOrders, setEditOrders] = useState(null);
  const [viewOrders, setViewOrders] = useState(null);
  const [createOrders, setcreateOrders] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMoveToProduction, setIsMoveToProduction] = useState(false);
  const [form] = Form.useForm();
  const [customerOptions, setCustomerDetails] = useState(null);
  const [LocationOptions, setLocationDetails] = useState(null);
  const [MaterialOptions, setMaterialDetails] = useState(null);
  const [otherMatInput, setOtherMatInput] = useState(null);
  const [AvailableQuantityForProduction, setAvailableQuantityForProduction] =
    useState(null);
  const [JumboOptions, setJumbodetails] = useState(null);
  const [jumboData, setJumboData] = useState(null);
  const [availableQuantity, setAvailableQuantity] = useState(0);
  const [LabelOptions, setLabeldetails] = useState(null);
  const [reservedQuantity, setReservedQuantity] = useState(null);
  const [OrderSize, setOrderSize] = useState({ width: "", length: "" });
  const [quantity, setqty] = useState(null);
  const [OrderStatusQuery, setOrderStatusQuery] = useState("");
  const [selectedJumboId, setSelectedJumboId] = useState(null); // State to store selected Jumbo Serial No
  const [selectedLabelId, setSelectedLabelId] = useState(null); // State to store selected Jumbo Serial No
  const [selectedCoreId, setSelectedCoreId] = useState(null); // State to store selected Jumbo Serial No
  const [ribbonOptions, setRibbonOptions] = useState([]);
  const [otherMaterials, setOtherMaterials] = useState([]);
  const [selectedMaterialId, setSelectedMaterialId] = useState(null); // State to store selected Material ID
  const [quantityError, setQuantityError] = useState("");
  const [numericError, setNumericError] = useState("");
  const [reservedQuantityError, setReservedQuantityError] = useState(null);
  const loggedinuser = localStorage.getItem("usernameHarnet");
  const loggedinUserId = localStorage.getItem("userID");
  const harnetRoleAccess =
    JSON.parse(localStorage.getItem("harnetRoleAccess")) || [];
  const userRoleHarnet = localStorage.getItem("userRoleHarnet") || [];
  // console.log("loginusername", loggedinuser);
  const [orderDate, setOrderDate] = useState(new Date());
  // console.log("current date", orderDate.toLocaleDateString("en-CA"));
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const { RangePicker } = DatePicker;
  const [moveToProductionClicked, setMoveToProductionClicked] = useState(false);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [isDispatchModalVisible, setIsDispatchModalVisible] = useState(false);
  const [isDispatch, setIsDispatch] = useState(false);
  const [orderqty, setOrderQuantity] = useState(null);
  const [typeFilter, settypeFilter] = useState("");
  const [radioutton, setRadioutton] = useState("1");
  const [searchQuery, setSearchQuery] = useState("");
  const [rows, setRows] = useState([
    {
      id: 1,
      store_id: null,
      quantity: null,
      isUnique: false,
      availableQuantity: 0,
    },
  ]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);
  useEffect(() => {
    console.log("typeFilter", typeFilter);
  }, [typeFilter]);
  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };
  useEffect(()=>{console.log("viewOrders",viewOrders);
  },[viewOrders])
  const handleOrderSize = () => {
    if (typeFilter === "1") {
      ReadRecord(
        `select count(*) AS count from label_master where status = 1 and label_length=${OrderSize?.length} and label_width=${OrderSize?.width} and label_ups=${OrderSize?.label_ups}`
      ).then((resLabel) => {
        if (resLabel.data[0].rows[0].count > 0) {
          message.error("Label Master Already Exists, Check the Status");
          setOrderSize({ length: "", width: "", label_ups: "" });
        } else {
          CreateRecord("labelMaster", [
            {
              label_length: OrderSize?.length,
              label_width: OrderSize?.width,
              label_ups: OrderSize?.label_ups,
              status: "1",
            },
          ]).then(() => {
            message.success("Master Added !");
            getLabelDetails();
            setOrderSize({ length: "", width: "", label_ups: "" });
          });
        }
      });
    } else {
      ReadRecord(
        `select count(*) AS count from ribbon_master where status = 1 and ribbon_length=${OrderSize?.length} and ribbon_width=${OrderSize?.width}`
      ).then((resLabel) => {
        if (resLabel.data[0].rows[0].count > 0) {
          message.error("Ribbon Master Already Exists, Check the Status");
          setOrderSize({ length: "", width: "" });
        } else {
          CreateRecord("ribbonMaster", [
            {
              ribbon_length: OrderSize?.length,
              ribbon_width: OrderSize?.width,
              status: "1",
            },
          ]).then(() => {
            message.success("Master Added !");
            fetchRibbonOptions();
            setOrderSize({ length: "", width: "" });
          });
        }
      });
    }
  };

  useEffect(() => {
    apiCheck(
      pagination.current,
      pagination.pageSize,
      searchQuery,
      formatDate(fromDate),
      formatDate(toDate),
      radioutton,
      OrderStatusQuery
    );
  }, [searchQuery]);
  useEffect(() => {
    getJumboData("1");
    getCustomerDetails();
    getLocationDetails();
    getMaterialDetail();
    getLabelDetails();
    getCoreDetails();
    fetchRibbonOptions();
    getStoreOtherMaterials();
  }, []);
  const handleOrderType = (value) => {
    getJumboData(value);
    setRadioutton(value);
    // apiCheck(
    //   1,
    //   pagination.pageSize,
    //   searchQuery,
    //   formatDate(fromDate),
    //   formatDate(toDate),
    //   value,
    //   OrderStatusQuery
    // );
  };
  const handleTypeFilter = (value) => {
    settypeFilter(value);
    getJumboDetails(value);
    form.resetFields();
  };
  // useEffect(() => {
  //     if (selectedJumboId && selectedMaterialId) {
  //         fetchAvailableQuantity(selectedJumboId, selectedMaterialId);
  //     }
  // }, [selectedJumboId, selectedMaterialId]);
  const handleJumboTypeChange = async (value) => {
    try {
      const res = await ReadRecord(
        `SELECT t1.id,t1.material_id,t1.type,t2.material_name FROM jumbo_master t1 join material_master t2 ON t1.material_id=t2.id where t1.id='${value}' order by t1.id desc limit 1`
      );
      console.log("created by value", res.data[0].rows[0]);

      if (res.data[0].count > 0) {
        form.setFieldValue(
          "type",
          res.data[0].rows[0].type == "1"
            ? "Label"
            : res.data[0].rows[0].type == "2"
            ? "Ribbon"
            : "Please select type"
        );
        form.setFieldValue(
          "material_id",
          res.data[0].rows[0].material_name || ""
        );
      } else {
        form.setFieldValue("type", "Please select type");
        form.setFieldValue("material_id", "");
      }
    } catch (error) {
      console.error("Error fetching jumbo data:", error);
    }
  };
  async function fetchAvailableQuantity(label_id, Mat_id) {
    try {
      // Make API call to fetch available quantity
      const response = await ReadRecord(
        `SELECT quantity from store WHERE type_id= '${label_id}' and Jumbo_id='${Mat_id}' and type=${
          typeFilter == "1" ? "3" : "5"
        } and ActiveStatus=1`
      );

      // Update available quantity state
      if (response.data[0].count > 0) {
        const quantity = parseFloat(response.data[0].rows[0].quantity);
        console.log(
          "triggered total quantity",
          response.data[0].rows[0].quantity
        );

        // Convert available quantity to number
        setAvailableQuantity(quantity);
      } else {
        // If no data is returned, set available quantity to 0
        setAvailableQuantity(0);
      }
    } catch (error) {
      console.error("Error fetching available quantity:", error);
    }
  }
  const apiRefresh = () => {
    console.log(
      "apiRefresh",
      pagination.current,
      pagination.pageSize,
      searchQuery,
      fromDate,
      toDate,
      radioutton,
      OrderStatusQuery
    );

    apiCheck(
      pagination.current,
      pagination.pageSize,
      searchQuery,
      fromDate,
      toDate,
      radioutton,
      OrderStatusQuery
    );
  };
  async function apiCheck(
    page,
    pageSize,
    search = "",
    fromDate = null,
    toDate = null,
    radiouttons = "1",
    query = ""
  ) {
    // async function apiCheck(page, pageSize, search = "") {
    console.log("useEffect triggered", fromDate);
    let dateFilter = "";
    let searchByUser = "";
    if (fromDate && toDate) {
      dateFilter = `AND order_date BETWEEN '${fromDate}' AND '${toDate}'`;
    }
    let searchFilter = "";
    let offset = (page - 1) * pageSize;
    if (search != "") {
      searchFilter = `AND (t1.order_number like '%${search.trim()}%'
             or t1.order_date like '%${search.trim()}%'
             or t2.customer_name like '%${search.trim()}%'
              or t3.location_name like '%${search.trim()}%'
               or t7.material_name like '%${search.trim()}%'
                or t6.username like '%${search.trim()}%'
                or t5.jumbo_type like '%${search.trim()}%'
                or t1.order_status like '%${search.trim()}%'
                or t1.order_quantity like '%${search.trim()}%')`;
      offset = 0;
    }
    if (userRoleHarnet && userRoleHarnet != "1" && userRoleHarnet != "3") {
      searchByUser = `and t1.user_id='${loggedinUserId}'`;
    }

    let searchData = `SELECT 
        t1.*, 
        t2.customer_name, 
        t3.location_name,
        t7.material_name,
        t5.material_id AS Mat_id,
        t7.material_name AS material_id,
        t5.jumbo_type,
        t5.type,
        t6.username 
    FROM 
        orders AS t1 
    JOIN 
        customer_master AS t2 ON t1.customer_id = t2.id 
    JOIN 
        location_master AS t3 ON t1.location_id = t3.id 
    JOIN 
        jumbo_master AS t5 ON t1.jumbo_id = t5.id 
    JOIN 
        user_master AS t6 ON t1.user_id = t6.id        
    JOIN 
        material_master AS t7 ON t5.material_id = t7.id
    WHERE 
        t1.status = 1 
        AND t1.ActiveStatus = 1 ${searchByUser} and order_type like '%${radiouttons}%' ${query} ${searchFilter} ${dateFilter} ORDER BY t1.id DESC limit ${pageSize} offset ${offset}`;

    let searchDataCount = `SELECT COUNT(*) as TotalCount
        FROM  orders AS t1 
         JOIN 
        customer_master AS t2 ON t1.customer_id = t2.id 
    JOIN 
        location_master AS t3 ON t1.location_id = t3.id 
    JOIN 
        jumbo_master AS t5 ON t1.jumbo_id = t5.id 
    JOIN 
        user_master AS t6 ON t1.user_id = t6.id        
     JOIN 
         material_master AS t7 ON t5.material_id = t7.id

        WHERE t1.status = 1 and t1.ActiveStatus = 1 ${searchByUser} and t1.order_type like '%${radiouttons}%' ${query} ${dateFilter} ${searchFilter}`;
    const apiRequest1 = ReadRecord(searchData).then((res) => {
      console.log("apiRequest1", res);
      return res;
    });
    const apiRequest2 = ReadRecord(searchDataCount).then((res) => {
      console.log("apiRequest2", res);
      return res;
    });
    try {
      //   Execute both requests concurrently
      const [data1, data2] = await Promise.all([apiRequest1, apiRequest2]);

      //   Handle the responses
      // setOtherData(data1); // Update with the data from Endpoint1
      setOrderDetails(data1.data[0].rows); // Update with the data from Endpoint2
      let total = data2.data[0].rows[0].TotalCount; // Update with the data from Endpoint2
      // Update pagination state
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: page,
        pageSize: pageSize,
        total: total, // Update total from your API response
      }));

      // Reset pagination to the first page
      //tableRef.current.onChange(1);
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
    }
  }

  function getCustomerDetails() {
    let searchByUser = "";
    if (userRoleHarnet && userRoleHarnet != "1" && userRoleHarnet != "3") {
      searchByUser = `and CreatedBy='${loggedinUserId}'`;
    }
    ReadRecord(
      `select * from customer_master where status=1 and ActiveStatus=1 ${searchByUser}`
    ).then((res) => {
      setCustomerDetails(res.data[0]?.rows);
    });
  }

  function getLocationDetails() {
    ReadRecord(
      `select * from location_master where status=1 and ActiveStatus=1`
    ).then((res) => {
      setLocationDetails(res.data[0]?.rows);
    });
  }
  function getLabelDetails() {
    ReadRecord(
      `SELECT id,label_length,label_width,label_measure,label_ups FROM label_master WHERE status = 1 and ActiveStatus = 1`
    ).then((res) => {
      setLabeldetails(res.data[0]?.rows);
    });
  }
  function getCoreDetails() {
    ReadRecord(
      `SELECT id,core_type,core_size,core_dia,core_dia_measure,core_length,core_length_measure FROM core_master WHERE status = 1 and ActiveStatus = 1 order by id desc`
    ).then((res) => {
      setSelectedCoreId(res.data[0]?.rows);
    });
  }
  const fetchRibbonOptions = async () => {
    try {
      const response = await ReadRecord(
        `SELECT id,ribbon_length,ribbon_width,ribbon_measure FROM ribbon_master WHERE status = 1 and ActiveStatus = 1`
      );
      setRibbonOptions(response.data[0]?.rows || []);
    } catch (error) {
      console.error("Error fetching Label options:", error);
    }
  };
  const getStoreOtherMaterials = async () => {
    try {
      const response = await ReadRecord(
        `SELECT t1.id,t2.other_material_name FROM store t1 join other_materials t2 ON t1.type_id  = t2.id  WHERE t1.status = 1 and t1.ActiveStatus = 1 and type = 2`
      );
      setOtherMaterials(response.data[0]?.rows || []);
    } catch (error) {
      console.error("Error fetching Label options:", error);
    }
  };

  function getMaterialDetail() {
    ReadRecord(
      `SELECT id,material_name FROM material_master WHERE status = 1 and ActiveStatus = 1`
    ).then((res) => {
      setMaterialDetails(res.data[0]?.rows);
    });
  }

  function getJumboDetails(value) {
    ReadRecord(
      `SELECT id,jumbo_type FROM jumbo_master WHERE status = 1 and ActiveStatus = 1 and type = '${value}' order by id desc;`
    ).then((res) => {
      setJumbodetails(res.data[0]?.rows || []);
    });
  }
  function getJumboData(value) {
    console.log("hryyy");

    ReadRecord(
      `SELECT id,jumbo_type FROM jumbo_master WHERE status = 1 and ActiveStatus = 1 and type = '${value}' order by id desc;`
    ).then((res) => {
      setJumboData(res.data[0]?.rows || []);
    });
  }
  const columns = [
    {
      title: "S.No.",
      dataIndex: "serial",
      key: "serial",
      align: "center",
      width: 10,
      render: (text, record, index) =>
        index + (pagination.current - 1) * pagination.pageSize + 1,
    },
    {
      title: "Order ID",
      dataIndex: "order_number",
      key: "order_number",
      align: "center",
      width: 50,
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.name.includes(value),
    },
    // {
    //     title: 'Ordered By',
    //     dataIndex: 'username',
    //     key: 'username',
    //     align: 'center',
    //     width: 50,
    // },
    {
      title: "Ordered Date",
      dataIndex: "CreatedDate",
      key: "CreatedDate",
      align: "center",
      width: 100,
      // render: (record) => {
      //   // return <span>{formatDate(expected_delivery_date)}</span>;
      //   // return <span>{record.CreatedDate}</span>;
      // },
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
      align: "center",
      width: 50,
      filters: customerOptions?.map((item) => ({
        text: item.customer_name,
        value: item.id,
      })),
      filteredValue: filteredInfo.customer_name || null,
    },
    {
      title: "Jumbo Type",
      dataIndex: "jumbo_type",
      key: "jumbo_type",
      width: 50,
      align: "center",
      filters: jumboData?.map((item) => ({
        text: item.jumbo_type,
        value: item.id,
      })),
      filteredValue: filteredInfo.jumbo_type || null,
    },
    {
      title: "Material Type",
      dataIndex: "material_name",
      key: "material_name",
      width: 50,
      align: "center",
      filters: MaterialOptions?.map((item) => ({
        text: item.material_name,
        value: item.material_name,
      })),
      filteredValue: filteredInfo.material_name || null,
      onFilter: (value, record) => record.material_name === value,
    },
    // radioutton==="1"&&
    // {
    //   title: "sSize",
    //   dataIndex: "label_size",
    //   key: "mtype1",
    //   width: 80,
    //   align: "center",
    //   render: (index, record) => {
    //     if (record.order_type == "1") {
    //       const selected = LabelOptions?.find(
    //         (option) => option.id === record.label_size
    //       );
    //       return `${selected?.label_width} x ${selected?.label_length}`;
    //     } else {
    //       const selected = ribbonOptions?.find(
    //         (option) => option.id === record.label_size
    //       );
    //       return `${selected?.ribbon_width} x ${selected?.ribbon_length}`;
    //     }
    //   },
    // },
    // radioutton==="2"&&
    // {
    //   title: "kSize",
    //   dataIndex: "label_size",
    //   key: "mtype1",
    //   width: 80,
    //   align: "center",
    //   render: (index, record) => {
    //     if (record.order_type == "1") {
    //       const selected = LabelOptions?.find(
    //         (option) => option.id === record.label_size
    //       );
    //       return `${selected?.label_width} x ${selected?.label_length}`;
    //     } else {
    //       const selected = ribbonOptions?.find(
    //         (option) => option.id === record.label_size
    //       );
    //       return `${selected?.ribbon_width} x ${selected?.ribbon_length}`;
    //     }
    //   },
    // },
    ...(radioutton === "1"
      ? [
          {
            title: "Size",
            dataIndex: "label_size",
            key: "label_id",
            width: 80,
            align: "center",
            filters: LabelOptions?.map((item) => ({
              text: `${item?.label_width} x ${item?.label_length} - (${item?.label_ups} ups)`,
              value: item.id,
            })),
            filteredValue: filteredInfo.label_id || null,
            render: (index, record) => {
              // if (record.order_type == "1") {
              const selected = LabelOptions?.find(
                (option) => option.id === record.label_size
              );

              return selected
                ? `${selected.label_width} x ${selected.label_length} - (${selected.label_ups} ups)`
                : "-";
              // } else {
              //     const selected = ribbonOptions?.find(
              //         (option) => option.id === record.label_size
              //     );
              //     return `${selected?.ribbon_width} x ${selected?.ribbon_length}`;
              // }
            },
          },
        ]
      : radioutton === "2"
      ? [
          {
            title: "Size",
            dataIndex: "label_size",
            key: "ribbon_id",
            width: 80,
            align: "center",
            filters: ribbonOptions?.map((item) => ({
              text: `${item?.ribbon_width} x ${item?.ribbon_length}`,
              value: item.id,
            })),
            filteredValue: filteredInfo.ribbon_id || null,
            render: (index, record) => {
              // if (record.order_type == "1") {
              //     const selected = LabelOptions?.find(
              //         (option) => option.id === record.label_size
              //     );
              //     return `${selected?.label_width} x ${selected?.label_length}`;
              // } else {
              const selected = ribbonOptions?.find(
                (option) => option.id === record.label_size
              );
              return selected
                ? `${selected.ribbon_width} x ${selected.ribbon_length}`
                : "-";
              // }
            },
          },
        ]
      : []),

    {
      title: "Order Quantity",
      dataIndex: "order_quantity",
      key: "order_quantity",
      align: "center",
      width: 50,
    },
    {
      title: "Order Status",
      dataIndex: "order_status",
      key: "orderstatus",
      align: "center",
      width: 50,
      filters: [
        { text: "Awaiting Approval", value: "Awaiting Approval" },
        { text: "Approved", value: "Approved" },
        { text: "Dispatched", value: "Dispatched" },
        { text: "In Progress", value: "In Progress" },
        { text: "Production Completed", value: "Production Completed" },
        { text: "Rejected", value: "Rejected" },
        { text: "Production Rejected", value: "Production Rejected" },
      ],
      filteredValue: filteredInfo.orderstatus || null,
      onFilter: (value, record) => record.order_status === value,
      // onFilter: (value, record) => {console.log("sefddsf",value); return record},
      // onclick: (value, record) => {
      // console.log("valuevalue", value);
      // apiCheck(
      //   pagination.current,
      //   pagination.pageSize,
      //   searchQuery,
      //   fromDate,
      //   toDate,
      //   radioutton,
      //   value
      // );
      // return record;
      // },
      render: (order_status) => {
        let color;
        switch (order_status) {
          case "Approved":
            color = "purple";
            break;
          case "Dispatched":
            color = "green";
            break;
          case "Awaiting Approval":
            color = "blue";
            break;
          case "In Progress":
            color = "gold";
            break;
          case "Production Completed":
            color = "gold";
            break;
          case "Rejected":
            color = "brown";
            break;
          case "Production Rejected":
            color = "brown";
            break;
          default:
            color = "default";
            break;
        }
        return (
          <Tag style={{ fontSize: "10px", fontWeight: "bold" }} color={color}>
            {order_status}
          </Tag>
        );
      },
    },
    // {
    //     title: 'Expected Delivery Date',
    //     dataIndex: 'expected_delivery_date',
    //     key: 'expected_delivery_date',
    //     align:'center',
    //     width:50,
    //     render: (expected_delivery_date) => {
    //         const date = new Date(expected_delivery_date);
    //         return <span>{date.toLocaleDateString('en-GB')}</span>; // 'en-GB' specifies the format DD/MM/YYYY
    //     }
    // },
    // {
    //   title: "Location",
    //   dataIndex: "location_name",
    //   key: "location_name",
    //   align: "center",
    //   width: 50,
    // },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      width: 100,
      align: "center",
      render: (text, record) => (
        <>
          {(harnetRoleAccess && harnetRoleAccess.includes("11")) ||
          (userRoleHarnet && userRoleHarnet === "1") ? (
            <Button
              size="small"
              icon={<EyeOutlined />}
              type="primary"
              style={{ backgroundColor: "#be991d" }}
              title="View"
              onClick={() => handleView(record)}
            ></Button>
          ) : (
            ""
          )}
          &nbsp;
          {(harnetRoleAccess && harnetRoleAccess.includes("12")) ||
          (userRoleHarnet && userRoleHarnet === "1") ? (
            <Button
              size="small"
              icon={<EditOutlined />}
              style={{ backgroundColor: "#005c9f" }}
              type="primary"
              title="Edit"
              onClick={() => handleEdit(record)}
              disabled={
                record.order_status == "Rejected" ||
                record.order_status == "In Progress" ||
                record.order_status == "Dispatched" ||
                record.order_status == "Approved" ||
                record.order_status == "Move to Production" ||
                record.order_status == "Production Completed"
              }
            ></Button>
          ) : (
            ""
          )}
          &nbsp;
          {(harnetRoleAccess && harnetRoleAccess.includes("13")) ||
          (userRoleHarnet && userRoleHarnet === "1") ? (
            <Button
              size="small"
              icon={<CloseOutlined />}
              type="primary"
              style={{ backgroundColor: "#d7524b" }}
              title="Cancel"
              onClick={() => handleDelete(record)}
              disabled={
                record.order_status == "Rejected" ||
                record.order_status == "In Progress" ||
                record.order_status == "Dispatched" ||
                record.order_status == "Approved" ||
                record.order_status == "Move to Production" ||
                record.order_status == "Production Completed"
              }
            ></Button>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  const showModal = () => {
    form.setFieldValue("");
    setIsModalVisible(true);
    setIsRejectModalVisible(false);
    setEditOrders(null);
    setViewOrders(null);
    setQuantityError(null);
    setcreateOrders(true);
    settypeFilter("");
  };
  const handleCancel2 = () => {
    setIsMoveToProduction(false);
    setTimeout(() => {
      handleCancel();
    }, 2000);
  };
  const handleCancel = () => {
    setIsRejectModalVisible(false);
    setIsDispatchModalVisible(false);
    setTimeout(() => {
      setIsModalVisible(false);
      settypeFilter("");
      form.resetFields();
      setEditOrders("");
      setViewOrders("");
      setQuantityError("");
      setcreateOrders("");
      setReservedQuantityError("");
      setReservedQuantity("");
      setOtherMatInput(null);
    }, 10);
  };

  const onFinish = async (values) => {
    console.log("values", values);
    if (parseFloat(values.order_quantity) === 0) {
      alert("Quantity should be greater than 0.");
      console.log("Quantity should be greater than 0.");
      return;
    }
    if (values.order_quantity == "" || values.order_quantity === undefined) {
      alert("Please enter quantity");
      console.log("Please enter quantity");
      return;
    }
    let expected_delivery_date_new = new Date(values.expected_delivery_date);
    let today = new Date();

    if (values.expected_delivery_date < values.order_date) {
      alert("Expected delivery date should be greater than order date.");
      console.log("Expected delivery date should be greater than order date.");
      return;
    }
    if (!editOrders && values.expected_delivery_date < values.order_date) {
      alert("Expected delivery date should not be less than today's date.");
      console.log(
        "Expected delivery date should not be less than today's date."
      );
      return;
    }

    //let updatedExpectedDeliveryDate = expected_delivery_date_new ? expected_delivery_date_new.toLocaleDateString('en-CA') : null;

    if (
      expected_delivery_date_new == undefined ||
      expected_delivery_date_new == ""
    ) {
      let newex = "";
    } else {
      expected_delivery_date: expected_delivery_date_new.toLocaleDateString(
        "en-CA"
      );
    }
    console.log("submitted values", values);
    let data = {
      location_id: values.location_id,
      customer_id: values.customer_id,
      user_id: loggedinUserId,
      jumbo_id: values.jumbo_id,
      order_date: values.order_date,
      expected_delivery_date: values.expected_delivery_date
        ? expected_delivery_date_new.toLocaleDateString("en-CA")
        : null,
      order_quantity: values.order_quantity,
      per_roll: values.per_roll,
      label_ups: values.label_ups,
      label_size: values.label_size,
      core_type: values.core_type,
      order_remarks: values.order_remarks,
      order_quantity: Number(values.order_quantity),
      core_size: values.core_size,
      order_status: "Awaiting Approval",
      order_type: Number(typeFilter),
    };
    if (editOrders) {
      const updatedData = {
        ...editOrders,
        ...values,
        order_quantity: Number(values.order_quantity), // Convert order_quantity to a number
      };
      console.log("updatedData", updatedData);
      delete updatedData.material_id;
      await UpdateRecord("orders", [updatedData])
        .then((res) => {
          console.log("updated records", res);
          message.success("Order Updated successfully");
          apiRefresh();
          setQuantityError("");
        })
        .catch((err) => {
          console.log(err);
        });
      setIsModalVisible(false);
      form.resetFields();
    } else {
      await CreateRecord("orders", [data])
        .then((res) => {
          console.log("inserted data", res.feedback[0]?.ROWID);
          ReadRecord(
            `select * from orders where id='${res.feedback[0]?.ROWID}'`
          ).then((res) => {
            let order_id = res.data[0]?.rows[0].order_number;
            console.log("newly created order id", order_id);
            let notificationData = {
              user_id: loggedinUserId,
              notification: order_id + " created sucessfully !",
              order_status: "Awaiting Approval",
            };
            CreateRecord("notifications", [notificationData]).then((res) => {
              console.log("notification triggered", res);
            });
          });
          apiRefresh();
          handleCancel();
          message.success("Order Placed successfully");
          setQuantityError("");
          //getOrderDetails();
        })
        .catch((err) => {
          console.log(err);
        });
      setIsModalVisible(false);
      form.resetFields();
    }
    // window.location.reload();
  };
  const handleView = (values) => {
    const newRecords = { ...values };
    handleTypeFilter(values.order_type);
    setIsModalVisible(true);

    setViewOrders(newRecords);
    form.setFieldsValue(newRecords);
    setMoveToProductionClicked(false);
    settypeFilter(values.order_type);
    setOtherMatInput(values.order_quantity);
  };
  // const handleMoveToProduction = async (string) => {
  //   if (string == "open") {
  //     console.log("viewOrders", viewOrders);
  //     setIsMoveToProduction(true);
  //     if (typeFilter == "1") {
  //       ReadRecord(
  //         `select * from store where type_id='${viewOrders.label_size}' AND type='3' AND ActiveStatus=1 AND status = 1`
  //       ).then((response) => {
  //         let quantity = 0;
  //         if (
  //           response.data[0]?.count == 0 ||
  //           Number(response.data[0].rows[0].quantity) -
  //             Number(response.data[0].rows[0].reserved_quantity) >
  //             Number(viewOrders.order_quantity)
  //         ) {
  //           quantity = viewOrders.order_quantity;
  //         } else {
  //           quantity =
  //             Number(viewOrders.order_quantity) -
  //             (Number(response.data[0].rows[0].quantity) -
  //               Number(response.data[0].rows[0].reserved_quantity));
  //         }
  //         console.log("quantity", quantity);
  //         setOtherMatInput(quantity ? quantity : 0);
  //         const number =
  //           Number(response?.data[0]?.rows[0]?.quantity) -
  //             Number(response?.data[0]?.rows[0]?.reserved_quantity) >=
  //           0
  //             ? Number(response?.data[0]?.rows[0]?.quantity) -
  //               Number(response?.data[0]?.rows[0]?.reserved_quantity)
  //             : 0;
  //         setAvailableQuantityForProduction(number);
  //       });
  //     } else {
  //       ReadRecord(
  //         `select * from store where type_id='${viewOrders.label_size}' AND type='5' AND ActiveStatus=1 AND status = 1`
  //       ).then((response) => {
  //         let quantity = 0;
  //         if (response.data[0]?.count == 0) {
  //           quantity = viewOrders.order_quantity;
  //         } else {
  //           quantity =
  //             Number(viewOrders?.order_quantity) -
  //             (Number(response.data[0]?.rows[0]?.quantity) -
  //               Number(response?.data[0].rows[0]?.reserved_quantity));
  //         }
  //         console.log("quantity", quantity);
  //         setOtherMatInput(quantity ? quantity : 0);
  //         const Number =
  //           response.data[0]?.rows[0]?.quantity -
  //             Number(response?.data[0]?.rows[0]?.reserved_quantity) >=
  //           0
  //             ? Number(response?.data[0]?.rows[0]?.quantity) -
  //               Number(response?.data[0]?.rows[0]?.reserved_quantity)
  //             : 0;
  //         setAvailableQuantityForProduction(Number);
  //       });
  //     }
  //   } else {
  //     if (otherMatInput <= 0) {
  //       message.warning("quantity should not be 0 or less than 0");
  //     } else {
  //       setMoveToProductionClicked(true);
  //       setIsModalVisible(false);

  //       try {
  //         const orderViewValues = [{ ...viewOrders }];
  //         // console.log("inserting data", orderViewValues);

  //         let label_quantity_sqm = 0;
  //         if (orderViewValues[0].order_type == "1") {
  //           // console.log("LabelOptions",LabelOptions);
  //           const selected = LabelOptions.find(
  //             (option) => option.id === orderViewValues[0].label_size
  //           );
  //           // console.log("selected",selected);
  //           label_quantity_sqm = Math.round(
  //             ((((Number(selected.label_width) + 5) *
  //               (Number(selected.label_length) + 3)) /
  //               645.16) *
  //               Number(otherMatInput)) /
  //               1550
  //           );
  //         } else {
  //           const selected = ribbonOptions.find(
  //             (option) => option.id === orderViewValues[0].label_size
  //           );
  //           // console.log("Ribbon");
  //           label_quantity_sqm = Math.round(
  //             ((selected.ribbon_width * selected.ribbon_length) / 1000) *
  //               otherMatInput
  //           );
  //         }
  //         console.log("label_quantity_sqm", label_quantity_sqm);
  //         const createResponse = await CreateRecord("production", [
  //           {
  //             order_id: orderViewValues[0].id,
  //             jumbo_id: orderViewValues[0].jumbo_id,
  //             user_id: orderViewValues[0].user_id,
  //             production_date: new Date().toLocaleDateString("en-CA"),
  //             production_label_quantity: otherMatInput,
  //             production_label_quantity_sqm: Number(label_quantity_sqm),
  //             production_status: "Awaiting Approval",
  //             label_id: orderViewValues[0].label_size,
  //             production_type: 1,
  //             core_size: orderViewValues[0].core_size,
  //           },
  //         ]);
  //         console.log("Production inserted data:", createResponse);

  //         // Update record in "orders" table
  //         const updateResponse = await UpdateRecord("orders", [
  //           {
  //             id: viewOrders.id,
  //             order_status: "In Progress",
  //           },
  //         ]);
  //         console.log("Order status updated:", updateResponse);
  //         // handleCancel2();
  //         message.success("Order moved to production");
  //         window.location.reload();
  //         apiRefresh();
  //         form.resetFields();
  //       } catch (error) {
  //         console.error("Error moving order to production:", error);
  //         message.error("Failed to move order to production");
  //       }
  //     }
  //   }
  // };

  const handleMoveToProduction = async (status) => {
    if (status === "open") {
      console.log("viewOrders", viewOrders);
      setIsMoveToProduction(true);

      const queryType = typeFilter === "1" ? "3" : "5";
      const query = `select * from store where type_id='${viewOrders.label_size}' and Jumbo_id='${viewOrders.jumbo_id}' AND type='${queryType}' AND ActiveStatus=1 AND status=1`;

      try {
        const response = await ReadRecord(query);
        let quantity = 0;
        const storeData = response?.data?.[0]?.rows?.[0];

        if (!storeData || response?.data?.[0]?.count === 0) {
          // No store data or count is 0, set quantity to the order quantity
          quantity = viewOrders.order_quantity;
        } else {
          const availableQuantity =
            storeData.quantity - storeData.reserved_quantity;
          if (availableQuantity > viewOrders.order_quantity) {
            quantity = viewOrders.order_quantity;
          } else {
            quantity = viewOrders.order_quantity - availableQuantity;
          }
        }

        console.log("quantity", quantity);
        setOtherMatInput(quantity > 0 ? quantity : 0);

        const availableQuantityForProduction = storeData
          ? Math.max(storeData.quantity - storeData.reserved_quantity, 0)
          : 0;

        setAvailableQuantityForProduction(availableQuantityForProduction);
      } catch (error) {
        console.error("Error fetching store data:", error);
        message.error("Failed to fetch store data");
      }
    } else {
      if (otherMatInput <= 0) {
        message.warning("Quantity should not be 0 or less than 0");
      } else {
        setMoveToProductionClicked(true);
        setIsModalVisible(false);

        try {
          const orderViewValues = [{ ...viewOrders }];
          let label_quantity_sqm = 0;

          if (orderViewValues[0].order_type === "1") {
            // const selected = LabelOptions.find(
            //   (option) => option.id === orderViewValues[0].label_size
            // );
            // if (selected) {
            //   label_quantity_sqm = Math.round(
            //     ((((Number(selected.label_width) + 5) *
            //       (Number(selected.label_length) + 3)) /
            //       645.16) *
            //       Number(otherMatInput)) /
            //       1550
            //   );
            // }
          } else {
            const selected = ribbonOptions.find(
              (option) => option.id === orderViewValues[0].label_size
            );
            if (selected) {
              label_quantity_sqm = Math.round(
                ((selected.ribbon_width * selected.ribbon_length) / 1000) *
                  otherMatInput
              );
            }
          }

          console.log("label_quantity_sqm", label_quantity_sqm);
          const createResponse = await CreateRecord("production", [
            {
              order_id: orderViewValues[0].id,
              jumbo_id: orderViewValues[0].jumbo_id,
              user_id: orderViewValues[0].user_id,
              production_date: new Date().toLocaleDateString("en-CA"),
              production_label_quantity: otherMatInput,
              production_label_quantity_sqm:
                orderViewValues[0].order_type == "1" ? null : label_quantity_sqm,
              production_status: "Awaiting Approval",
              label_id: orderViewValues[0].label_size,
              production_type: 1,
              core_size: orderViewValues[0].core_size,
              order_based_quantity: otherMatInput,
              pro_quantity: otherMatInput,
            },
          ]);

          console.log("Production inserted data:", createResponse);

          const updateResponse = await UpdateRecord("orders", [
            {
              id: viewOrders.id,
              order_status: "In Progress",
            },
          ]);

          console.log("Order status updated:", updateResponse);

          message.success("Order moved to production");
          window.location.reload();
          apiRefresh();
          form.resetFields();
        } catch (error) {
          console.error("Error moving order to production:", error);
          message.error("Failed to move order to production");
        }
      }
    }
  };

  const handleEdit = (record) => {
    const newRecords = { ...record };
    console.log("All records", newRecords);
    setIsModalVisible(true);
    setEditOrders(newRecords);
    form.setFieldsValue(newRecords);
    settypeFilter(record.order_type);
    // handleLabelChange(record.label_size);
    // fetchAvailableQuantity(record.label_size,record.Mat_id);
    setSelectedLabelId(record.label_size);
    getJumboDetails(record.order_type);
  };

  const handleDelete = (record) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to cancel this order ?"
    );
    if (isConfirmed) {
      console.log("Before deleting record", record.id);
      let data = {
        id: record.id,
        status: false,
        ActiveStatus: false,
      };
      UpdateRecord("deleteOrders", [data]).then((res) => {
        message.info("Order has been cancelled");

        apiRefresh();

        //getOrderDetails();
        console.log("User record deleted", res);
      });
      let dataNotify = {
        user_id: record.user_id,
        notification: record.order_number + " Cancelled!",
        order_status: "Cancelled",
      };
      CreateRecord("notifications", [dataNotify]).then((res) => {
        console.log("notification triggered", res);
      });
    }
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      console.log("From Date:", formatDate(dates[0]));
      console.log("To Date:", formatDate(dates[1]));
      // Set fromDate and toDate states
      setFromDate(dates[0]);
      setToDate(dates[1]);
      // Fetch data with date filter
      apiCheck(
        1,
        pagination.pageSize,
        searchQuery,
        formatDate(dates[0]),
        formatDate(dates[1]),
        radioutton,
        OrderStatusQuery
      );
    } else {
      // If dates are cleared, set fromDate and toDate to null
      console.log("Dates Cleared");
      setFromDate(null);
      setToDate(null);
      // Fetch data without date filter
      apiCheck(1, pagination.pageSize, searchQuery, null, null);
    }
  };

  const handleLabelChange = (value) => {
    const data = form.getFieldsValue();
    console.log("labeldata", data);
    setSelectedLabelId(value);
    // fetchAvailableQuantity(value);
    form.setFieldValue("order_quantity", "");
    setNumericError("");
    setQuantityError("");
  };

  // Function to handle Material type change
  const handleMaterialChange = (value) => {
    console.log("material id", value);
    setSelectedMaterialId(value);
  };
  const handleQuantityChange = (e) => {
    const value = e.target.value;

    // Check if the input is numeric
    if (!/^(?!0)\d+(\.\d+)?$/.test(value)) {
      setNumericError("Quantity should accept only numeric values!");
      setQuantityError("");
      return;
    } else {
      setNumericError("");
    }

    // const enteredQuantity = parseFloat(value);

    // // Check if entered quantity exceeds available quantity
    // if (enteredQuantity > availableQuantity) {
    //   setQuantityError("Quantity exceeds available stock");
    // } else {
    //   setQuantityError("");
    // }
  };

  const handleReject = () => {
    setIsRejectModalVisible(true); // Show modal when rejecting
  };

  const handleRejectSubmit = async (value) => {
    try {
      let records = [{ ...viewOrders }];
      let data = {
        id: records[0].id,
        order_status: "Rejected",
        rejected_reason: value.rejected_reason,
      };
      const updateResponse = await UpdateRecord("reject_orders", [data]).then(
        (res) => {
          console.log("Order status updated:", res);
        }
      );
      let dataNotify = {
        user_id: records[0]?.user_id,
        notification: records[0]?.order_number + " Rejected!",
        order_status: "Rejected",
      };
      CreateRecord("notifications", [dataNotify]).then((res) => {
        console.log("notification triggered", res);
      });
      console.log("Order status updated:", updateResponse);

      message.info("Order has been rejected");
      // setIsRejectModalVisible(false);
      handleCancel();
      apiRefresh();
    } catch (error) {
      console.error("Error moving order to rejected:", error);
      message.error("Failed to move order to rejected");
    }
  };
  const handleNewCancel = () => {
    setIsRejectModalVisible(false);
  };
  const handleOtherMatCancel = () => {
    setIsMoveToProduction(false);
    setOtherMatInput(null);
  };
  const handleDispatchCancel = () => {
    setIsDispatchModalVisible(false);
    setRows([
      {
        id: 1,
        store_id: null,
        quantity: null,
        isUnique: false,
        availableQuantity: 0,
      },
    ]);
  };
  const handleApprove = async () => {
    try {
      let records = [{ ...viewOrders }];
      let reservedQt = 0;
      let orderqt = 0;
      let actualReserve = 0;
      let quantity = 0;

      console.log("All view records", records);
      if (typeFilter == "1") {
        ReadRecord(
          `select * from store where type_id='${records[0].label_size}' and Jumbo_id='${records[0].jumbo_id}' AND type='3' AND ActiveStatus=1 AND status = 1`
        ).then((res) => {
          if (res.data[0]?.count == 0) {
            setQuantityError(true);
            setQuantityError(`Available stock - ${res.data[0]?.count}`);
            return;
          }
          let StoreTableRes = res.data[0]?.rows[0]; //All store table records
          quantity = Number(StoreTableRes.quantity); //store table lable quantity
          reservedQt = Number(StoreTableRes.reserved_quantity); //store table lable reserved quantity
          orderqt = Number(records[0]?.order_quantity); //form order quantity
          actualReserve = reservedQt + orderqt; // adding store table reserved quantity + form order quantity

          if (quantity < orderqt) {
            console.log("Not approve ");
            setQuantityError(true);
            setQuantityError(`Available stock - ${quantity}`);
            return;
          } else if (quantity < actualReserve) {
            console.log("Not approve ");
            setReservedQuantityError("Reserved Quantity execeeds");
            return;
          } else {
            ReadRecord(
              `select count(*) as count from orders where label_size='${records[0].label_size}' and (order_status = 'Awaiting Approval' or order_status = 'Production Completed' or order_status = 'Production Rejected')  and ActiveStatus = 1 and id < '${records[0].id}' and order_type = '1';`
            ).then(async (respon) => {
              console.log("response", respon.data[0].rows[0].count);
              if (respon.data[0].rows[0].count > 0) {
                const confirmed = window.confirm(
                  "Already Order with same Size Pending for Confirmation!"
                );
                if (confirmed) {
                  console.log("approve");
                  let data = {
                    id: records[0]?.id,
                    order_status: "Approved",
                  };
                  await UpdateRecord("approve_orders", [data]).then((res) => {
                    console.log("order approved");
                  });
                  let dataNotify = {
                    user_id: records[0]?.user_id,
                    notification:
                      records[0]?.order_number + " has been approved!",
                    order_status: "Approved",
                  };
                  await CreateRecord("notifications", [dataNotify]).then(
                    (res) => {
                      console.log("notification triggered", res);
                    }
                  );
                  let storeData = {
                    type: "3",
                    type_id: records[0].label_size,
                    reserved_quantity: actualReserve,
                    Jumbo_id: records[0].jumbo_id,
                  };
                  await UpdateRecord("reserved_qty_store", [storeData]).then(
                    (res) => {
                      console.log("store table updated");
                      message.info(
                        "Order has been Approved, Ready to dispatch"
                      );
                      apiRefresh();
                      setIsModalVisible(false);
                      form.resetFields();
                    }
                  );
                }
              } else {
                console.log("approve");
                let data = {
                  id: records[0]?.id,
                  order_status: "Approved",
                };
                await UpdateRecord("approve_orders", [data]).then((res) => {
                  console.log("order approved");
                });
                let dataNotify = {
                  user_id: records[0]?.user_id,
                  notification:
                    records[0]?.order_number + " has been approved!",
                  order_status: "Approved",
                };
                await CreateRecord("notifications", [dataNotify]).then(
                  (res) => {
                    console.log("notification triggered", res);
                  }
                );
                let storeData = {
                  type: "3",
                  type_id: records[0].label_size,
                  reserved_quantity: actualReserve,
                  Jumbo_id: records[0].jumbo_id,
                };
                await UpdateRecord("reserved_qty_store", [storeData]).then(
                  (res) => {
                    console.log("store table updated");
                    message.info("Order has been Approved, Ready to dispatch");
                  }
                );
                apiRefresh();
                setIsModalVisible(false);
                form.resetFields();
              }
            });
          }
        });
      } else {
        ReadRecord(
          `select * from store where type_id='${records[0].label_size}' and Jumbo_id='${records[0].jumbo_id}' AND type='5' AND ActiveStatus=1 AND status=1`
        ).then((res) => {
          if (res.data[0]?.count == 0) {
            setQuantityError(true);
            setQuantityError(`Available stock - ${res.data[0]?.count}`);
            return;
          }
          let StoreTableRes = res.data[0]?.rows[0]; //All store table records
          quantity = Number(StoreTableRes.quantity); //store table lable quantity
          reservedQt = Number(StoreTableRes.reserved_quantity); //store table lable reserved quantity
          orderqt = Number(records[0]?.order_quantity); //form order quantity
          actualReserve = reservedQt + orderqt; // adding store table reserved quantity + form order quantity

          if (quantity < orderqt) {
            console.log("Not approve ");
            setQuantityError(true);
            setQuantityError(`Available stock - ${quantity}`);
            return;
          } else if (quantity < actualReserve) {
            console.log("Not approve ");
            setReservedQuantityError("Reserved Quantity execeeds");
            return;
          } else {
            ReadRecord(
              `select count(*) as count from orders where label_size='${records[0].label_size}' and (order_status = 'Awaiting Approval' or order_status = 'Production Completed' or order_status = 'Production Rejected')  and ActiveStatus = 1 and id < '${records[0].id}' and order_type = '2';`
            ).then((respon) => {
              console.log("response", respon.data[0].rows[0].count);
              if (respon.data[0].rows[0].count > 0) {
                const confirmed = window.confirm(
                  "Already Order with same Size Pending for Confirmation!"
                );
                if (confirmed) {
                  console.log("approve");
                  let data = {
                    id: records[0]?.id,
                    order_status: "Approved",
                  };
                  UpdateRecord("approve_orders", [data]).then((res) => {
                    console.log("order approved");
                  });
                  let dataNotify = {
                    user_id: records[0]?.user_id,
                    notification:
                      records[0]?.order_number + " has been approved!",
                    order_status: "Approved",
                  };
                  CreateRecord("notifications", [dataNotify]).then((res) => {
                    console.log("notification triggered", res);
                  });
                  let storeData = {
                    type: "5",
                    type_id: records[0].label_size,
                    reserved_quantity: actualReserve,
                    Jumbo_id: records[0].jumbo_id,
                  };
                  UpdateRecord("reserved_qty_store", [storeData]).then(
                    (res) => {
                      console.log("store table updated");
                      message.info(
                        "Order has been Approved, Ready to dispatch"
                      );
                      apiRefresh();
                      setIsModalVisible(false);
                      form.resetFields();
                    }
                  );
                }
              } else {
                console.log("approve");
                let data = {
                  id: records[0]?.id,
                  order_status: "Approved",
                };
                UpdateRecord("approve_orders", [data]).then((res) => {
                  console.log("order approved");
                });
                let dataNotify = {
                  user_id: records[0]?.user_id,
                  notification:
                    records[0]?.order_number + " has been approved!",
                  order_status: "Approved",
                };
                CreateRecord("notifications", [dataNotify]).then((res) => {
                  console.log("notification triggered", res);
                });
                let storeData = {
                  type: "5",
                  type_id: records[0].label_size,
                  reserved_quantity: actualReserve,
                  Jumbo_id: records[0].jumbo_id,
                };
                UpdateRecord("reserved_qty_store", [storeData]).then((res) => {
                  console.log("store table updated");
                  message.info("Order has been Approved, Ready to dispatch");
                  // apiCheck(
                  //   pagination.current,
                  //   pagination.pageSize,
                  //   searchQuery,
                  //   null,
                  //   null
                  // );
                  apiRefresh();
                  setIsModalVisible(false);
                  form.resetFields();
                });
              }
            });
          }
        });
      }

      // if (quantity < orderqt && quantity < actualReserve) {
      //     console.log("Not approve ");
      //     // setQuantityError(true);
      //     return;
      // } else {
      //     console.log("approve ");

      //     let data = {
      //         id: records[0].id,
      //         order_status: 'Approved',
      //     }
      // const updateResponse = await UpdateRecord("approve_orders", [data]).then((res) => {
      // });
      // let storeData = {
      //     type: '3',
      //     type_id: records[0].label_size,
      //     reserved_quantity: actualReserve
      // }

      // const updateStore = await UpdateRecord("reserved_qty_store", [storeData]).then((res) => {
      // })

      // message.info("Order has been Approved, Ready to dispatch");
      // setIsModalVisible(false);
      // apiCheck(pagination.current, pagination.pageSize, searchQuery, null, null);
      // }

      // if (reservedQuantity >= orderqty) {
      //     let data = {
      //         id: records[0].id,
      //         order_status: 'Approved',
      //     }
      //     const updateResponse = await UpdateRecord("approve_orders", [data]).then((res) => {
      //         console.log("Order status updated:", res);
      //     });

      //     let storeData = {
      //         type:'3',
      //         type_id:records[0].label_size,
      //         reserved_quantity:records[0].order_quantity
      //     }
      //     console.log("already quantity", reservedQuantity);

      //     console.log("Order status updated:", updateResponse);
      //     const updateStore = await UpdateRecord("reserved_qty_store", [storeData]).then((res) => {
      //         console.log("store reserved quantity updated", res);
      //     })
      //     console.log("Store data update:", updateStore);

      //     message.info("Order has been Approved, Ready to dispatch");
      //     setIsModalVisible(false);
      //     apiCheck(pagination.current, pagination.pageSize, searchQuery, null, null);

      // }
      // else{
      //     setQuantityError(true);
      //     return;
      // }
    } catch (error) {
      console.error("Error moving order to production:", error);
      message.error("Failed to move order to production");
    }
  };

  useEffect(() => {
    console.log("rows", rows);
  }, [rows]);
  const addRow = () => {
    setRows([
      ...rows,
      {
        id: rows.length + 1,
        store_id: null,
        quantity: null,
        isUnique: false,
        availableQuantity: 0,
      },
    ]);
  };

  const handleOtherMaterialChange = (value, index) => {
    const isDuplicate = rows.some(
      (row, i) => i !== index && row.store_id === value
    );

    if (isDuplicate) {
      message.warning(
        "This material has already been selected. Please choose a different one."
      );
    }

    let availableQuantity = 0;
    ReadRecord(
      `select quantity from store where status = 1 and ActiveStatus = 1 and type = 2 and id = '${value}' and ActiveStatus = 1`
    ).then((response) => {
      if (response.data[0].count > 0) {
        availableQuantity = response.data[0].rows[0].quantity;
      }

      const updatedRows = rows.map((row, i) =>
        i === index
          ? {
              ...row,
              store_id: value,
              quantity: 0,
              isUnique: !isDuplicate,
              availableQuantity: availableQuantity,
              dispatch_id: viewOrders?.id,
            }
          : row
      );
      setRows(updatedRows);
    });
  };

  const handleOtherQuantityChange = (value, index) => {
    setRows((prevRows) => {
      return prevRows.map((row, i) => {
        if (i === index) {
          const exceedsQuantity = value > row.availableQuantity;

          // Check if the entered quantity exceeds the available quantity
          if (exceedsQuantity) {
            message.error(
              `Entered quantity (${value}) exceeds available quantity (${row.availableQuantity}).`
            );
            return row; // Return the row unchanged if it exceeds the limit
          }

          // Update the quantity if it is within the limit
          return { ...row, quantity: value, exceedsQuantity };
        }
        return row; // No change for other rows
      });
    });
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows
      .filter((row, i) => i !== index)
      .map((row, i) => ({
        ...row,
        id: i + 1, // Reassign ID to maintain sequence
      }));
    setRows(updatedRows);
  };
  const handleDispatch = async (string) => {
    if (string === "openModal") {
      setIsDispatchModalVisible(true);
      setRows([
        {
          id: 1,
          store_id: null,
          quantity: null,
          isUnique: false,
          availableQuantity: 0,
        },
      ]);
    } else {
      try {
        for (let i = 0; i < rows.length; i++) {
          const row = rows[i];
          if (!row.store_id || !row.quantity || row.isUnique === false) {
            // Show alert if any value is missing or store_id is not unique
            message.error(
              `Row ${
                i + 1
              } is incomplete or has a non-unique material selection.`
            );
            return; // Stop the submission process
          }
        }
        await UpdateRecord(
          "store",
          rows.map((row) => ({
            id: row.store_id,
            quantity: Number(row.availableQuantity) - Number(row.quantity),
          }))
        );

        let storeQuantity = 0;
        let storeReservedQuantity = 0;
        let calcStoreQuantity = 0;
        let calcStoreReservedQuantity = 0;
        let orderQuantity = 0;
        const orderViewValues = [{ ...viewOrders }];
        console.log("All inserting data", orderViewValues);

        const data = {
          order_id: orderViewValues[0].id,
          order_number: orderViewValues[0].order_number,
          location_id: orderViewValues[0].location_id,
          customer_id: orderViewValues[0].customer_id,
          jumbo_id: orderViewValues[0].jumbo_id,
          user_id: orderViewValues[0].user_id,
          order_date: orderViewValues[0].order_date,
          expected_delivery_date: orderViewValues[0].expected_delivery_date,
          order_quantity: orderViewValues[0].order_quantity,
          order_status: "Completed",
          per_roll: orderViewValues[0].per_roll,
          label_id: orderViewValues[0].label_size,
          core_id: orderViewValues[0].core_size,
          order_remarks: orderViewValues[0].order_remarks,
          label_ups: orderViewValues[0].label_ups,
          dispatch_remarks: form.getFieldValue("dispatch_remarks"),
        };

        // Insert data into dispatch
        const dispatchResponse = await CreateRecord("dispatch", [data]);
        console.log("Inserting data to dispatch", dispatchResponse);
        await CreateRecord(
          "dispatch_child",
          rows.map((row) => ({
            dispatch_id: dispatchResponse.ROWID,
            store_id: row.store_id,
            quantity: Number(row.quantity),
          }))
        );
        const dataNotify = {
          user_id: orderViewValues[0].user_id,
          notification:
            orderViewValues[0].order_number + " Dispatched Successfully!",
          order_status: "Dispatch",
        };

        // Insert notification
        const notificationResponse = await CreateRecord("notifications", [
          dataNotify,
        ]);
        console.log("Notification triggered", notificationResponse);

        // Update order status
        await UpdateRecord("orders", [
          {
            id: viewOrders.id,
            order_status: "Dispatched",
          },
        ]);

        // Read store quantities
        const storeResponse = await ReadRecord(
          `SELECT quantity, reserved_quantity FROM store WHERE type= ${
            typeFilter == "1" ? 3 : 5
          } and Jumbo_id='${orderViewValues[0].jumbo_id}'  AND type_id='${
            orderViewValues[0].label_size
          }' and ActiveStatus = 1`
        );

        if (storeResponse.data[0]?.rows.length > 0) {
          orderQuantity = Number(orderViewValues[0].order_quantity);
          storeQuantity = Number(storeResponse.data[0].rows[0].quantity);
          storeReservedQuantity = Number(
            storeResponse.data[0].rows[0]?.reserved_quantity
          );

          calcStoreQuantity = storeQuantity - orderQuantity;
          calcStoreReservedQuantity = storeReservedQuantity - orderQuantity;

          const storeData = {
            type: typeFilter == "1" ? "3" : "5",
            type_id: orderViewValues[0].label_size,
            quantity: calcStoreQuantity,
            Jumbo_id: orderViewValues[0].jumbo_id,
            reserved_quantity: calcStoreReservedQuantity,
          };

          // Update store quantities
          const updateStoreResponse = await UpdateRecord(
            "dispatchquantityupdate",
            [storeData]
          );
          console.log("Updated store quantities", updateStoreResponse);
        }

        message.success("Order moved to dispatch successfully");
        // setIsModalVisible(false);
        form.resetFields();
        apiRefresh();
        handleCancel();
      } catch (error) {
        console.error("Error moving order to dispatch:", error);
        message.error("Failed to move order to dispatch");
      }
    }
  };

  //   const formatDate = (date) => {
  //     if (!date) return ""; // Return empty string if date is null or undefined

  //     // Ensure date is of type Date
  //     if (!(date instanceof Date)) {
  //       // If date is not a Date object, try to parse it
  //       date = new Date(date);
  //       // If parsing fails, return empty string
  //       if (isNaN(date.getTime())) return "";
  //     }

  //     // Format the date as DD-MM-YYYY
  //     const day = date.getDate().toString().padStart(2, "0");
  //     const month = (date.getMonth() + 1).toString().padStart(2, "0");
  //     const year = date.getFullYear();

  //     return `${year}-${month}-${day}`;
  //   };
  const options = [
    {
      label: "Label",
      value: "1",
    },
    {
      label: "Ribbon",
      value: "2",
    },
  ];
  const handleTableChange = (pagination, filters) => {
    console.log("filters", filters);

    let query = "";
    const filterClauses = [];

    if (
      filters?.material_name != null ||
      filters?.order_number != null ||
      filters?.orderstatus != null ||
      filters?.jumbo_type != null ||
      filters?.customer_name != null ||
      filters?.label_id != null ||
      filters?.ribbon_id != null
    ) {
      setFilteredInfo(filters);
    } else {
      setFilteredInfo({});
    }
    if (filters.orderstatus && filters.orderstatus.length > 0) {
      const orderStatusInClause = filters.orderstatus
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (orderStatusInClause) {
        filterClauses.push(`t1.order_status IN (${orderStatusInClause})`);
      }
    }
    if (filters.label_id && filters.label_id.length > 0) {
      const LabelIdlInClause = filters.label_id
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (LabelIdlInClause) {
        filterClauses.push(`t1.label_size IN (${LabelIdlInClause})`);
      }
    }
    if (filters.ribbon_id && filters.ribbon_id.length > 0) {
      const RibbonIdlInClause = filters.ribbon_id
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (RibbonIdlInClause) {
        filterClauses.push(`t1.label_size IN (${RibbonIdlInClause})`);
      }
    }
    if (filters.material_name && filters.material_name.length > 0) {
      const MaerialInClause = filters.material_name
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (MaerialInClause) {
        filterClauses.push(`t7.material_name IN (${MaerialInClause})`);
      }
    }
    if (filters.customer_name && filters.customer_name.length > 0) {
      const CustomerNameInClause = filters.customer_name
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (CustomerNameInClause) {
        filterClauses.push(`t1.customer_id IN (${CustomerNameInClause})`);
      }
    }
    if (filters.jumbo_type && filters.jumbo_type.length > 0) {
      const JumboTypeInClause = filters.jumbo_type
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (JumboTypeInClause) {
        filterClauses.push(`t1.jumbo_id IN (${JumboTypeInClause})`);
      }
    }
    if (filterClauses.length > 0) {
      query = `AND ${filterClauses.join(" AND ")}`;
    }
    setOrderStatusQuery(query);
    return apiCheck(
      pagination.current,
      pagination.pageSize,
      searchQuery,
      formatDate(fromDate),
      formatDate(toDate),
      radioutton,
      query
    );
  };
  const clearFilters = () => {
    setFilteredInfo({});
    setFromDate("");
    setToDate("");
    setSearchQuery("");
    setOrderStatusQuery("");
    setTimeout(() => {
      apiCheck(
        pagination.current,
        pagination.pageSize,
        "",
        "",
        "",
        radioutton,
        ""
      );
    }, 0);
  };
  const clearFiltersForRadio = (value) => {
    setFilteredInfo({});
    setFromDate("");
    setToDate("");
    setSearchQuery("");
    setOrderStatusQuery("");
    setTimeout(() => {
      apiCheck(1, pagination.pageSize, "", "", "", value, "");
    }, 0);
  };

  return (
    <div>
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              color: "#333",
              marginRight: "auto",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            Orders
          </p>
          {filteredInfo && Object.keys(filteredInfo).length > 0 && (
            <Button
              onClick={clearFilters}
              style={{ borderColor: "red", color: "red" }}
            >
              Clear Filters
            </Button>
          )}
          &nbsp;
          <Radio.Group
            options={options}
            // onChange={(e) => handleTypeFilter(e)}
            onChange={(e) => {
              handleOrderType(e.target.value);
              clearFiltersForRadio(e.target.value);
            }}
            value={radioutton}
            optionType="button"
            buttonStyle="solid"
          />
          &nbsp;
          <RangePicker
            value={[fromDate, toDate]}
            onChange={handleDateChange}
            style={{ width: 250 }}
          />
          &nbsp;
          <Input
            placeholder="Search"
            value={searchQuery}
            allowClear
            style={{ width: 200, margin: "0 16px" }}
            prefix={<SearchOutlined />}
            onChange={(e) => handleSearch(e.target.value)}
          />
          {(harnetRoleAccess && harnetRoleAccess.includes("10")) ||
          (userRoleHarnet && userRoleHarnet === "1") ? (
            <Button type="primary" onClick={showModal} icon={<PlusOutlined />}>
              Create Orders
            </Button>
          ) : (
            ""
          )}
        </div>
        <Table
          className="custom-table custom-table-th"
          onChange={handleTableChange}
          size="small"
          bordered
          dataSource={orders}
          columns={columns}
          rowClassName={(record, index) =>
            index % 2 === 1 ? "even-row" : "odd-row"
          }
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            // onChange: (page, pageSize) => {
            //   apiCheck(
            //     page,
            //     pageSize,
            //     searchQuery,
            //     formatDate(fromDate),
            //     formatDate(toDate),
            //     radioutton
            //   );
            // },
            total: pagination.total,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </>
      <Modal
        title={
          <span>
            {editOrders
              ? "Edit Order"
              : viewOrders
              ? "View Order"
              : "Create Order"}{" "}
            &nbsp;
            <span style={{ marginLeft: "30%" }}>
              <Radio.Group
                options={options}
                // onChange={(e) => handleTypeFilter(e)}
                onChange={(e) => handleTypeFilter(e.target.value)}
                value={typeFilter}
                optionType="button"
                buttonStyle="solid"
                disabled={viewOrders || editOrders}
              />
            </span>
          </span>
        }
        centered
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
        help={reservedQuantityError}
        maskClosable={false}
      >
        {reservedQuantityError && (
          <p style={{ color: "red" }}>{reservedQuantityError}</p>
        )}
        &nbsp;
        <OrderForm
          form={form}
          onFinish={onFinish}
          loggedinuser={loggedinuser}
          viewOrders={viewOrders}
          JumboOptions={JumboOptions}
          handleMaterialChange={handleMaterialChange}
          customerOptions={customerOptions}
          LocationOptions={LocationOptions}
          LabelOptions={LabelOptions}
          selectedCoreId={selectedCoreId}
          formatDate={formatDate}
          orderDate={orderDate}
          MaterialOptions={MaterialOptions}
          handleLabelChange={handleLabelChange}
          quantityError={quantityError}
          handleQuantityChange={handleQuantityChange}
          TextArea={TextArea}
          createOrders={createOrders}
          editOrders={editOrders}
          handleApprove={handleApprove}
          handleDispatch={handleDispatch}
          handleReject={handleReject}
          isRejectModalVisible={isRejectModalVisible}
          isDispatchModalVisible={isDispatchModalVisible}
          handleNewCancel={handleNewCancel}
          handleRejectSubmit={handleRejectSubmit}
          handleMoveToProduction={handleMoveToProduction}
          numericError={numericError}
          selectedLabelId={selectedLabelId}
          handleJumboTypeChange={handleJumboTypeChange}
          typeFilter={typeFilter}
          ribbonOptions={ribbonOptions}
          handleDispatchCancel={handleDispatchCancel}
          otherMaterials={otherMaterials}
          rows={rows}
          addRow={addRow}
          setRows={setRows}
          handleOtherQuantityChange={handleOtherQuantityChange}
          handleOtherMaterialChange={handleOtherMaterialChange}
          handleDeleteRow={handleDeleteRow}
          isMoveToProduction={isMoveToProduction}
          handleOtherMatCancel={handleOtherMatCancel}
          otherMatInput={otherMatInput}
          setOtherMatInput={setOtherMatInput}
          AvailableQuantityForProduction={AvailableQuantityForProduction}
          setOrderSize={setOrderSize}
          OrderSize={OrderSize}
          handleOrderSize={handleOrderSize}
          userRoleHarnet={userRoleHarnet}
          harnetRoleAccess={harnetRoleAccess}
        />
      </Modal>
    </div>
  );
};
export default Order;
