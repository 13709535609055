import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  message,
  Col,
  Row,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  CreateRecord,
  ReadRecord,
  UpdateRecord,
} from "../../../Service/API/ApiService";
import { render } from "@testing-library/react";

const CoreMaster = () => {
  const [users, setUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editUserData, setEditUserData] = useState(null);
  const [ViewUserData, setViewUserData] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [coreValidationStatus, setCoreValidationStatus] = useState("");
  const [coreHelpMessage, setCoreHelpMessage] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    apiCheck(pagination.current, pagination.pageSize, searchQuery);
    localStorage.setItem("Title", "Core");
    //userListData();
    setSelectedStatus("Active");
  }, [searchQuery]);

  async function apiCheck(page, pageSize, search = "") {
    let searchFilter = "";
    let offset = (page - 1) * pageSize;

    if (search !== "") {
        const trimmedSearch = search.toLowerCase().trim();
        if (trimmedSearch === "active") {
            searchFilter = `AND status = 1`;
        } else if (trimmedSearch === "inactive") {
            searchFilter = `AND status = 0`;
        } else {
            // Properly encapsulate the search condition
            searchFilter = `AND (concat(core_type, " - ", core_dia, " ", core_dia_measure, " x ", core_length, " ", core_length_measure) LIKE '%${trimmedSearch}%')`;
        }
        offset = 0;
    }

    let searchData = `SELECT *, concat(core_type, " - ", core_dia, " ", core_dia_measure, " x ", core_length, " ", core_length_measure) as core
                      FROM core_master
                      WHERE ActiveStatus = 1 ${searchFilter}
                      ORDER BY status DESC, id DESC
                      LIMIT ${pageSize} OFFSET ${offset}`;

    let searchDataCount = `SELECT COUNT(*) as TotalCount
                           FROM core_master
                           WHERE ActiveStatus = 1 ${searchFilter}`;

    try {
        // Execute both requests concurrently
        const [data1, data2] = await Promise.all([
            ReadRecord(searchData),
            ReadRecord(searchDataCount)
        ]);

        // Handle the responses
        if (data1 && data1.data && data1.data[0]) {
            setUsers(data1.data[0].rows); // Update with the data from the first query
        }

        if (data2 && data2.data && data2.data[0]) {
            let total = data2.data[0].rows[0].TotalCount; // Update with the total count from the second query
            setPagination((prevPagination) => ({
                ...prevPagination,
                current: page,
                pageSize: pageSize,
                total: total, // Update total from your API response
            }));
        }
    } catch (error) {
        // Handle errors
        console.error("Error fetching data:", error);
    }
}


  const columns = [
    {
      title: "S.No.",
      dataIndex: "serial",
      key: "serial",
      align: "center",
      render: (text, record, index) =>
        index + (pagination.current - 1) * pagination.pageSize + 1,
    },
    {
      title: "Core",
      dataIndex: "core",
      key: "Core Type",
      align: "center",
      // render: (text, record) => (
      //   <span>
      //     {record.core_type +
      //       " - " +
      //       record.core_dia +
      //       " " +
      //       record.core_dia_measure +
      //       " x " +
      //       record.core_length +
      //       " " +
      //       record.core_length_measure}
      //   </span>
      // ),
    },
    // {
    //   title: "Core Size INCH",
    //   dataIndex: "core_size",
    //   key: "Core Size",
    //   align: "center",
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status) => <span className="font-table-inside">{status == 1 ? "Active" : "Inactive"}</span>,
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      align: "center",
      render: (text, record) => (
        <>
          <Button
          size="small"
            icon={<EyeOutlined />}
            style={{ backgroundColor: "#be991d" }}
            type="primary"
            title="View"
            onClick={() => handleView(record)}
          ></Button>
          &nbsp;
          <Button
          size="small"
            icon={<EditOutlined />}
            style={{ backgroundColor: "#005c9f" }}
            type="primary"
            title="Edit"
            onClick={() => handleEdit(record)}
          ></Button>
          &nbsp;
          <Button
          size="small"
            icon={<DeleteOutlined />}
            type="primary"
            style={{ backgroundColor: "#d7524b" }}
            title="Delete"
            onClick={() => handleDelete(record)}
          ></Button>
        </>
      ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
    setEditUserData(null);
    setViewUserData(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setEditUserData("");
    setViewUserData("");
    setCoreValidationStatus("");
    setCoreHelpMessage("");
  };

  const onFinish = (values) => {
    if (values.core_type == undefined || values.core_type == "") {
      setCoreValidationStatus("error");
      setCoreHelpMessage("please fill core type");
      return;
    }
    if (editUserData) {
      const existingCore = ReadRecord(`
    SELECT COUNT(*) AS count FROM core_master
    WHERE core_type = '${values.core_type}' and core_length='${values.core_length}' and core_dia='${values.core_dia}'
    AND (status = 1 or status=0) AND ActiveStatus=1 AND id!='${editUserData.id}'
  `).then((res) => {
        console.log("core count", res.data[0]?.rows[0].count);
        if (res.data[0]?.rows[0].count == 0) {
          submitForm(values);
        } else {
          setCoreValidationStatus("error");
          setCoreHelpMessage("Core type and size already existing");
          return;
        }
      });
    } else {
      const existingCore = ReadRecord(`
    SELECT COUNT(*) AS count FROM core_master
    WHERE core_type = '${values.core_type}' and core_length='${values.core_length}' and core_dia='${values.core_dia}'
    AND (status = 1 or status=0) AND ActiveStatus=1
  `).then((res) => {
        console.log("core count", res.data[0]?.rows[0].count);
        if (res.data[0]?.rows[0].count == 0) {
          submitForm(values);
        } else {
          setCoreValidationStatus("error");
          setCoreHelpMessage("Core type and size already existing");
          message.error("Already Exists!");
          return;
        }
      });
    }
  };

  const submitForm = (values) => {
    console.log("values", values);
    let data = {
      core_type: values.core_type,
      // core_size: values.core_size,
      status: values.status == "Active" ? 1 : 0,
      core_dia: Number(values.core_dia),
      core_dia_measure: values.core_dia_measure,
      core_length: Number(values.core_length),
      core_length_measure: values.core_length_measure,
    };
    if (editUserData) {
      const updatedData = { ...editUserData, ...values };
      updatedData.status = updatedData.status == "Active" ? 1 : 0;

      UpdateRecord("coreMaster", [updatedData])
        .then((res) => {
          message.success("Core updated successfully");

          //userListData();
          apiCheck(pagination.current, pagination.pageSize, searchQuery);

          setIsModalVisible(false);
        })
        .catch((error) => {
          console.error("Error updating record:", error);
        });
    } else if (ViewUserData) {
      console.log("view data", ViewUserData);
    } else {
      CreateRecord("coreMaster", [data]).then((res) => {
        console.log("created core data", res);
        setUsers([...users, values]);
        message.success("Core added successfully");
        //userListData();
        apiCheck(pagination.current, pagination.pageSize, searchQuery);
      });
    }
    setIsModalVisible(false);
    form.resetFields();
    handleCancel();
  };

  const handleView = (record) => {
    const recordWithStatusLabel = { ...record };

    recordWithStatusLabel.status =
      recordWithStatusLabel.status == 1 ? "Active" : "Inactive";

    setViewUserData(recordWithStatusLabel);
    setIsModalVisible(true);
    form.setFieldsValue(recordWithStatusLabel);
  };

  const handleEdit = (record) => {
    const recordWithStatusLabel = { ...record };

    recordWithStatusLabel.status =
      recordWithStatusLabel.status == 1 ? "Active" : "Inactive";
    console.log("record", recordWithStatusLabel);
    setEditUserData(recordWithStatusLabel);
    setIsModalVisible(true);
    form.setFieldsValue(recordWithStatusLabel);
  };

  const handleDelete = (record) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (isConfirmed) {
      console.log("Before deleting record", record.id);
      let data = {
        id: record.id,
        ActiveStatus: false,
      };
      UpdateRecord("deleteCoreMaster", [data]).then((res) => {
        //userListData();
        apiCheck(pagination.current, pagination.pageSize, searchQuery);
        message.info("Core has been deleted");

        console.log("User record deleted", res);
      });
    }
  };
  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  return (
    <div>
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              color: "#333",
              marginRight: "auto",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            Core Master
          </p>
          <Input
            placeholder="Search"
            allowClear
            style={{ width: 350, margin: "0 16px" }}
            prefix={<SearchOutlined />}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Button type="primary" onClick={showModal} icon={<PlusOutlined />}>
            Add New Core
          </Button>
        </div>
        {/* <Table dataSource={filteredUsers} scroll={{y:400}} columns={columns} rowClassName={(record, index) => */}
        <Table
        className="custom-table custom-table-th"
          size="small"
          bordered
          dataSource={users}
          columns={columns}
          rowClassName={(record, index) =>
            index % 2 === 1 ? "even-row" : "odd-row"
          }
          pagination={{
            pageSize: pagination.pageSize,
            onChange: (page, pageSize) => {
              apiCheck(page, pageSize);
            },
            total: pagination.total,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </>

      <Modal
        title={
          editUserData
            ? "Edit core"
            : ViewUserData
            ? "View core"
            : "Create core"
        }
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
      >
        <hr></hr>
        &nbsp;
        <Form
          form={form}
          autoComplete="off"
          name="createUserForm"
          onFinish={onFinish}
          layout="vertical"
          id="coreform"
          className="coreform"
          // labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          disabled={ViewUserData}
          initialValues={{
            status: "Active",
            core_dia_measure: "inches",
            core_length_measure: "m",
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span>
                    <span
                      title="Core Type"
                      style={{ color: "#ff4d4f", fontSize: 16 }}
                    >
                      *
                    </span>{" "}
                    Core Type
                  </span>
                }
                name="core_type"
                id="coretype"
                className="coretype"
                validateStatus={coreValidationStatus} // Validate status
                help={coreHelpMessage} // Help message
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              {" "}
              <Form.Item
                label="Status"
                name="status"
                id="status"
                className="status"
                rules={[{ required: true, message: "Please select status!" }]}
              >
                <Select
                  onChange={(value) => setSelectedStatus(value)}
                  placeholder="Select"
                >
                  <Select.Option value="Active">Active</Select.Option>
                  <Select.Option value="Inactive">Inactive</Select.Option>
                </Select>
              </Form.Item>{" "}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Core Dia"
                name="core_dia"
                id="coredia"
                className="coredia"
                rules={[
                  { required: true, message: "Please enter core size!" },
                  {
                    pattern: /^(?!0)\d+(\.\d+)?$/,
                    message: "Only number values!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Measure"
                name="core_dia_measure"
                wrapperCol={{ span: 12 }}
              >
                <Select placeholder="Size">
                  <Select.Option label="inches" id="inches" value="inches">
                    inches
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Core Length"
                name="core_length"
                id="corelength"
                className="corelength"
                rules={[
                  { required: true, message: "Please enter core size!" },
                  {
                    pattern: /^(?!0)\d+(\.\d+)?$/,
                    message: "Only number values!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Measure"
                name="core_length_measure"
                wrapperCol={{ span: 12 }}
              >
                <Select placeholder="Size">
                  <Select.Option label="mm" id="mm" value="mm">
                    mm
                  </Select.Option>
                  <Select.Option label="cm" id="cm" value="cm">
                    cm
                  </Select.Option>
                  <Select.Option label="m" id="m" value="m">
                    m
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {/* <Form.Item
            label="Core Size INCH"
            name="core_size"
            id="coresize"
            className="coresize"
            rules={[
              { required: true, message: "Please enter core size!" },
              {
                pattern: /^(?!0)\d+(\.\d+)?$/,
                message: "Core size should accept only numberic values!",
              },
            ]}
          >
            <Input />
          </Form.Item> */}
          {/* <Form.Item
            label="Status"
            name="status"
            id="status"
            className="status"
            rules={[{ required: true, message: "Please select status!" }]}
          >
            <Select
              onChange={(value) => setSelectedStatus(value)}
              placeholder="Select"
            >
              <Select.Option value="Active">Active</Select.Option>
              <Select.Option value="Inactive">Inactive</Select.Option>
            </Select>
          </Form.Item> */}

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            {ViewUserData ? null : (
              <>
                <Button
                  type="primary"
                  htmlType="submit"
                  id="customerformsubmit"
                  className="customerformsubmit"
                >
                  {editUserData ? "Update" : "Add Core"}
                </Button>
                <Button style={{ marginLeft: 8 }} onClick={handleCancel}>
                  Cancel
                </Button>
              </>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CoreMaster;
