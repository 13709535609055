import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Input, Card, Select, message } from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  ReadRecord,
  CreateRecord,
  UpdateRecord,
} from "../../../Service/API/ApiService";
import Title from "antd/es/skeleton/Title";

const MaterialMaster = () => {
  const [users, setUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editUserData, setEditUserData] = useState(null);
  const [ViewUserData, setViewUserData] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("Active");
  const [MaterialValidationStatus, setMaterialValidationStatus] = useState("");
  const [MaterialHelpMessage, setMaterialHelpMessage] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    apiCheck(pagination.current, pagination.pageSize, searchQuery);
    //userListData();
  }, [searchQuery]);

  async function apiCheck(page, pageSize, search = "") {
    let searchFilter = "";
    let offset = (page - 1) * pageSize;
    if (search != "") {
      if (search.toLowerCase().trim() === "active") {
        searchFilter = `AND status = 1`;
      } else if (search.toLowerCase().trim() === "inactive") {
        searchFilter = `AND status = 0`;
      } else {
        searchFilter = `AND (material_name like '%${search}%')`;
      }
      offset = 0;
    }

    let searchData = `SELECT * from material_master where ActiveStatus=1 ${searchFilter}  ORDER BY status DESC, id DESC  limit ${pageSize} offset ${offset}`;
    let searchDataCount = `SELECT COUNT(*) as TotalCount
    FROM material_master 
    WHERE ActiveStatus = 1 ${searchFilter}`;
    const apiRequest1 = ReadRecord(searchData).then((res) => {
      console.log("apiRequest1", res);
      return res;
    });
    const apiRequest2 = ReadRecord(searchDataCount).then((res) => {
      console.log("apiRequest2", res);
      return res;
    });
    try {
      //   Execute both requests concurrently
      const [data1, data2] = await Promise.all([apiRequest1, apiRequest2]);

      //   Handle the responses
      // setOtherData(data1); // Update with the data from Endpoint1
      setUsers(data1.data[0].rows); // Update with the data from Endpoint2
      let total = data2.data[0].rows[0].TotalCount; // Update with the data from Endpoint2
      // Update pagination state
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: page,
        pageSize: pageSize,
        total: total, // Update total from your API response
      }));

      // Reset pagination to the first page
      //tableRef.current.onChange(1);
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
    }
  }

  const columns = [
    {
      title: "S.No.",
      dataIndex: "serial",
      key: "serial",
      align: "center",

      render: (text, record, index) =>
        index + (pagination.current - 1) * pagination.pageSize + 1,
    },
    {
      title: "Material Name",
      dataIndex: "material_name",
      key: "Material Name",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",

      render: (status) => <span className="font-table-inside">{status == 1 ? "Active" : "Inactive"}</span>,
    },

    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      align: "center",

      render: (text, record) => (
        <>
          <Button
          size="small"
            icon={<EyeOutlined />}
            type="primary"
            style={{ backgroundColor: "#be991d" }}
            title="View"
            onClick={() => handleView(record)}
          ></Button>
          &nbsp;
          <Button
          size="small"
            icon={<EditOutlined />}
            style={{ backgroundColor: "#005c9f" }}
            type="primary"
            title="Edit"
            onClick={() => handleEdit(record)}
          ></Button>
          &nbsp;
          <Button
          size="small"
            icon={<DeleteOutlined />}
            type="primary"
            style={{ backgroundColor: "#d7524b" }}
            title="Delete"
            onClick={() => handleDelete(record)}
          ></Button>
        </>
      ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
    setEditUserData(null);
    setViewUserData(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setEditUserData("");
    setViewUserData("");
    setMaterialHelpMessage("");
    setMaterialValidationStatus("");
  };

  const onFinish = (values) => {
    // If adding new data, check if core type and size already exist
    if(editUserData){
      const existingCore = ReadRecord(`
        SELECT COUNT(*) AS count FROM material_master
        WHERE material_name = '${values.material_name}'
        AND (status = 1 or status=0) AND ActiveStatus=1 AND id!='${editUserData.id}'
      `).then((res) => {
      console.log("core count", res.data[0]?.rows[0].count);
      if (res.data[0]?.rows[0].count == 0) {
        submitForm(values);
      } else {
        setMaterialValidationStatus("error");
        setMaterialHelpMessage("Material name and type already exsiting");
        message.error("Already Exists!");
        return;
      }
    });
    }else{
      const existingCore = ReadRecord(`
        SELECT COUNT(*) AS count FROM material_master
        WHERE material_name = '${values.material_name}'
        AND (status = 1 or status=0) AND ActiveStatus=1
      `).then((res) => {
      console.log("core count", res.data[0]?.rows[0].count);
      if (res.data[0]?.rows[0].count == 0) {
        submitForm(values);
      } else {
        setMaterialValidationStatus("error");
        setMaterialHelpMessage("Material name and type already exsiting");
        message.error("Already Exists!");
        return;
      }
    });
    }
    
  };
  const submitForm = (values) => {
    let data = {
      material_name: values.material_name,
      status: values.status == "Active" ? 1 : 0,
    };
    if (editUserData) {
      const updatedData = { ...editUserData, ...values };
      updatedData.status = updatedData.status == "Active" ? 1 : 0;

      UpdateRecord("materialMaster", [updatedData])
        .then((res) => {
          message.success("Material updated successfully");

          apiCheck(pagination.current, pagination.pageSize, searchQuery);
          setIsModalVisible(false);
        })
        .catch((error) => {
          console.error("Error updating record:", error);
        });
    } else {
      CreateRecord("materialMaster", [data]).then((res) => {
        message.success("Material added successfully");

        console.log("created location data", res);
        setUsers([...users, values]);
        apiCheck(pagination.current, pagination.pageSize, searchQuery);
      });
    }
    setIsModalVisible(false);
    form.resetFields();
    handleCancel();
  };

  const handleView = (record) => {
    const recordWithStatusLabel = { ...record };

    recordWithStatusLabel.status =
      recordWithStatusLabel.status == 1 ? "Active" : "Inactive";

    setViewUserData(recordWithStatusLabel);
    setIsModalVisible(true);
    form.setFieldsValue(recordWithStatusLabel);
  };

  const handleEdit = (record) => {
    const recordWithStatusLabel = { ...record };

    recordWithStatusLabel.status =
      recordWithStatusLabel.status == 1 ? "Active" : "Inactive";
    console.log("record", recordWithStatusLabel);
    setEditUserData(recordWithStatusLabel);
    setIsModalVisible(true);
    form.setFieldsValue(recordWithStatusLabel);
  };

  const handleDelete = (record) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (isConfirmed) {
      console.log("Before deleting record", record.id);
      let data = {
        id: record.id,
        ActiveStatus: false,
      };
      UpdateRecord("deleteMaterialMaster", [data]).then((res) => {
        message.info("Material has been deleted");

        apiCheck(pagination.current, pagination.pageSize, searchQuery);
        console.log("User record deleted", res);
      });
    }
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  return (
    <div>
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              color: "#333",
              marginRight: "auto",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            Material Master
          </p>
          <Input
            placeholder="Search"
            allowClear
            style={{ width: 350, margin: "0 16px" }}
            prefix={<SearchOutlined />}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Button type="primary" onClick={showModal} icon={<PlusOutlined />}>
            Add New Material
          </Button>
        </div>
        {/* <Table dataSource={filteredUsers} scroll={{y:400}} columns={columns} rowClassName={(record, index) => */}
        <Table
        className="custom-table custom-table-th"
          size="small"
          bordered
          dataSource={users}
          columns={columns}
          rowClassName={(record, index) =>
            index % 2 === 1 ? "even-row" : "odd-row"
          }
          pagination={{
            pageSize: pagination.pageSize,
            onChange: (page, pageSize) => {
              apiCheck(page, pageSize);
            },
            total: pagination.total,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </>
      <Modal
        title={
          editUserData
            ? "Edit Material"
            : ViewUserData
            ? "View Material "
            : "Create Material"
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
      >
        <hr></hr>
        &nbsp;
        <Form
          form={form}
          name="createUserForm"
          onFinish={onFinish}
          layout="horizontal"
          autoComplete="off"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 10 }}
          disabled={ViewUserData}
          id="materailformid"
          className="materialform"
          initialValues={{ status: "Active" }}
        >
          <Form.Item
            label="Material Name"
            name="material_name"
            id="materialname"
            type="materialtype"
            rules={[{ required: true, message: "Please enter material name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            id="status"
            className="status"
            rules={[{ required: true, message: "Please select status!" }]}
          >
            <Select
              onChange={(value) => setSelectedStatus(value)}
              placeholder="Select"
            >
              <Select.Option value="Active">Active</Select.Option>
              <Select.Option value="Inactive">Inactive</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            {ViewUserData ? null : (
              <>
                <Button
                  type="primary"
                  htmlType="submit"
                  id="customerformsubmit"
                  className="customerformsubmit"
                >
                  {editUserData ? "Update" : "Add "}
                </Button>
                <Button style={{ marginLeft: 8 }} onClick={handleCancel}>
                  Cancel
                </Button>
              </>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default MaterialMaster;
