import { useEffect, useRef, useState } from "react";
import { ReadRecord } from "../../Service/API/ApiService";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  Button,
  Table,
  DatePicker,
  Flex,
  Spin,
  Card,
  theme,
  Radio,
} from "antd";
import {
  LoadingOutlined,
  ExportOutlined,
  SearchOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import formatDate from "../../Components/formatDate";
import logo from "../../Assests/harnetlogo.png";
const headers = [
  { label: "Sl.No.", key: "sl_no" },
  { label: "Dispatch ID", key: "dispatch_number" },
  { label: "Order ID", key: "order_number" },
  { label: "Customer", key: "customer_name" },
  { label: "Material Type", key: "material_name" },
  { label: "Type", key: "type" },
  { label: "Size", key: "label_id" },
  { label: "Order Quantity", key: "order_quantity" },
  { label: "Location", key: "location_name" },
  { label: "Order Status", key: "order_status" },
  { label: "Ordered Date", key: "order_date" },
  { label: "Dispatched Date", key: "ModifiedDate" },
  { label: "Dispatch Remarks", key: "dispatch_remarks" },
];

function DispatchReport() {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const [data, setData] = useState([]);
  const [downloadedData, setDownloadedData] = useState([]);
  const csvDownloadRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [LabelOptions, setLabeldetails] = useState(null);
  const [ribbonOptions, setRibbonOptions] = useState([]);
  const [radioutton, setRadioutton] = useState("");
  const [customerOptions, setCustomerDetails] = useState(null);
  const [LocationOptions, setLocationDetails] = useState(null);
  const [MaterialOptions, setMaterialDetails] = useState(null);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [OrderStatusQuery, setOrderStatusQuery] = useState("");
  const [labelAndRibbonData, setlabelAndRibbonData] = useState(null);
  const { RangePicker } = DatePicker;
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const exportPdf = useRef();

  const columns = [
    {
      title: "Sl.No.",
      dataIndex: "serial",
      key: "serial",
      align: "center",
      width: 10,
      render: (text, record, index) =>
        index + (pagination.current - 1) * pagination.pageSize + 1,
    },
    {
      title: "Dispatch ID",
      dataIndex: "dispatch_number",
      key: "order1",
      align: "center",
      width: 20,
    },
    {
      title: "Order ID",
      dataIndex: "order_number",
      key: "order1",
      align: "center",
      width: 20,
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
      align: "center",
      width: 10,
      filters: customerOptions?.map((item) => ({
        text: item.customer_name,
        value: item.id,
      })),
      filteredValue: filteredInfo.customer_name || null,
    },
    {
      title: "Material Type",
      dataIndex: "material_name",
      key: "material_name",
      width: 50,
      align: "center",
      filters: MaterialOptions?.map((item) => ({
        text: item.material_name,
        value: item.material_name,
      })),
      filteredValue: filteredInfo.material_name || null,
      onFilter: (value, record) => record.material_name === value,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "typee",
      width: 50,
      align: "center",
      filters: [
        { text: "Label", value: "1" },
        { text: "Ribbon", value: "2" },
      ],
      filteredValue: filteredInfo.typee || null,
      onFilter: (value, record) => record.type === value,
      render: (record) => {
        if (record == "1") {
          return `Label`;
        } else {
          return `Ribbon`;
        }
      },
    },
    {
      title: "Size",
      dataIndex: "Size",
      key: "Size",
      width: 80,
      align: "center",
      filters: labelAndRibbonData?.map((item) => ({
        text: item.Size,
        value: item.Size,
      })),
      filteredValue: filteredInfo.Size || null,
      onFilter: (value, record) => record.Size === value,
    },
    {
      title: "Order Quantity",
      dataIndex: "order_quantity",
      key: "order_quantity",
      width: 50,
      align: "center",
    },
    {
      title: "Location",
      dataIndex: "location_name",
      key: "location_name",
      align: "center",
      width: 50,
      filters: LocationOptions?.map((item) => ({
        text: item.location_name,
        value: item.id,
      })),
      filteredValue: filteredInfo.location_name || null,
      // onFilter: (value, record) => record.location_name === value,
    },
    {
      title: "Order Status",
      dataIndex: "order_status",
      key: "ordsts",
      align: "center",
      width: 50,
    },
    {
      title: "Ordered Date",
      dataIndex: "order_date",
      key: "order_date",
      width: 60,
      align: "center",
      render: (order_date) => {
        // const date = new Date(order_date);
        return (
          <span style={{ whiteSpace: "nowrap", fontSize: "10px" }}>
            {formatDate(order_date)}
          </span>
        ); // 'en-GB' specifies the format DD/MM/YYYY
      },
    },
    // {
    //   title: "Order Quantity",
    //   dataIndex: "order_quantity",
    //   key: "orderI6",
    //   align: "center",
    //   width: 50,
    // },
    {
      title: "Dispatched Date",
      dataIndex: "ModifiedDate",
      key: "orderDispatchedDate",
      align: "center",
      width: 50,
      render: (CreatedDate) => {
        const date = new Date(CreatedDate);
        return (
          <span style={{ whiteSpace: "nowrap", fontSize: "10px" }}>
            {formatDate(CreatedDate)}
          </span>
        ); // 'en-GB' specifies the format DD/MM/YYYY
      },
    },
    // {
    //   title: "Dispatch Remarks",
    //   dataIndex: "dispatch_remarks",
    //   key: "dispatch_remarks",
    //   align: "center",
    //   width: 50,
    //   render: (record) => {
    //     return record ? record : "-";
    //   },
    // },
  ];
  useEffect(() => {
    const nowIST = dayjs().tz("Asia/Kolkata");
    const startOfMonth = nowIST.startOf("month");
    const endOfMonth = nowIST.endOf("month");

    console.log("startOfMonth", startOfMonth);
    setFromDate(startOfMonth);
    setToDate(endOfMonth);
    getCustomerDetails();
    getLocationDetails();
    getMaterialDetail();
    getLabelDetails();
    fetchRibbonOptions();
    getlabelAndRibbonComboDetails();
  }, []);
  function getlabelAndRibbonComboDetails() {
    ReadRecord(
      `SELECT 
        label_master.id AS id,
        '1' AS type,
         CONCAT(label_master.label_width, ' x ', label_master.label_length,' - ','(',label_master.label_ups,' ups)') AS Size
    FROM
label_master
    UNION ALL SELECT 
        ribbon_master.id AS id,
        '2' AS type,
        CONCAT(ribbon_master.ribbon_width, ' x ', ribbon_master.ribbon_length) AS Size
    FROM
        ribbon_master`
    ).then((res) => {
      setlabelAndRibbonData(res.data[0]?.rows);
    });
  }
  function getCustomerDetails() {
    ReadRecord(
      `select * from customer_master where status=1 and ActiveStatus=1`
    ).then((res) => {
      setCustomerDetails(res.data[0]?.rows);
    });
  }

  function getLocationDetails() {
    ReadRecord(
      `select * from location_master where status=1 and ActiveStatus=1`
    ).then((res) => {
      setLocationDetails(res.data[0]?.rows);
    });
  }

  function getMaterialDetail() {
    ReadRecord(
      `select * from material_master where status=1 and ActiveStatus=1`
    ).then((res) => {
      setMaterialDetails(res.data[0]?.rows);
    });
  }

  useEffect(() => {
    if (fromDate && toDate) {
      fetchData(fromDate, toDate, radioutton);
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: 1,
        pageSize: pagination.pageSize,
      }));
      // fetchDataToDownload(fromDate, toDate);
    } else {
      setData([]);
      setIsLoading(false);
    }
  }, [fromDate, toDate]);
  function getLabelDetails() {
    ReadRecord(
      `SELECT id,label_length,label_width,label_measure,label_ups FROM label_master WHERE status = 1 and ActiveStatus = 1`
    ).then((res) => {
      setLabeldetails(res.data[0]?.rows);
    });
  }
  const fetchRibbonOptions = async () => {
    try {
      const response = await ReadRecord(
        `SELECT id,ribbon_length,ribbon_width,ribbon_measure FROM ribbon_master WHERE status = 1 and ActiveStatus = 1`
      );
      setRibbonOptions(response.data[0]?.rows || []);
    } catch (error) {
      console.error("Error fetching Label options:", error);
    }
  };
  const fetchDataToDownloadPDF = () => {
    console.log("downloading data", data);
    setIsLoading(true);
    if (fromDate && toDate) {
      const doc = new jsPDF({
        orientation: "landscape",
      });
      doc.setFontSize(18); // Set font size for the title
      doc.text("Harnet India Private Ltd.,", 110, 12);
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 139); // Dark blue color
      doc.text("DISPATCH DETAILS :", 15, 15);
      const logoImg = logo;
      doc.addImage(
        logoImg,
        "PNG",
        doc.internal.pageSize.getWidth() - 35,
        3,
        20,
        15
      );
      const tableStyles = {
        theme: "grid",
        styles: {
          fontSize: 8, // Adjust the font size here
          halign: "center",
          cellWidth: "wrap",
        },
        margin: { top: 20 },
      };

      // Adjusting the columns to include dataKey and styling
      const tableColumns = columns.map((col) => ({
        ...col,
        dataKey: col.dataIndex,
      }));
      const dataWithSerialNumbers = filteredData.map((row, index) => ({
        ...row,
        serial: index + 1, // Add the serial number manually
        // dispatch_remarks: row.dispatch_remarks ? row.dispatch_remarks : "-",
        label_id:
          row.type == "1"
            ? LabelOptions?.find((option) => option.id === row.label_id)
              ? `${
                  LabelOptions.find((option) => option.id === row.label_id)
                    ?.label_width
                } x ${
                  LabelOptions.find((option) => option.id === row.label_id)
                    ?.label_length
                } - (${
                  LabelOptions.find((option) => option.id === row.label_id)
                    ?.label_ups
                } ups)`
              : "-" // Default value in case no matching label is found
            : ribbonOptions?.find((option) => option.id === row.label_id)
            ? `${
                ribbonOptions.find((option) => option.id === row.label_id)
                  ?.ribbon_width
              } x ${
                ribbonOptions.find((option) => option.id === row.label_id)
                  ?.ribbon_length
              }`
            : "-",
        type: row.type == "1" ? "Label" : "Ribbon",
      }));
      // Generate the table with the provided styles
      doc.autoTable(tableColumns, dataWithSerialNumbers, tableStyles);
      const filename = `Dispatch_Report_${
        fromDate ? formatDate(fromDate) : "from"
      }_to_${toDate ? formatDate(toDate) : "to"}.pdf`;
      // Save the PDF
      doc.save(filename);
      setIsLoading(false);
    } else {
      setDownloadedData([]);
    }
  };

  const fetchDataToDownload = (
    fromDate = null,
    toDate = null,
    radiouttons = "",
    query = ""
  ) => {
    setIsLoading(true);
    let dateFilter = "";
    if (fromDate && toDate) {
      dateFilter = `AND t1.ModifiedDate BETWEEN '${formatDate(
        fromDate
      )} 00:00:00' AND '${formatDate(toDate)} 23:59:59'`;
      ReadRecord(`SELECT 
        t1.*, 
        t2.customer_name, 
        t3.location_name, 
        t4.jumbo_type,
        t4.type,
        t5.material_name, 
        t6.username, 
        t7.core_size,
        t8.order_type,
        CASE
        WHEN t8.order_type = '1' THEN CONCAT(t9.label_width, ' x ', t9.label_length,' - ','(',t9.label_ups,' ups)')
        WHEN t8.order_type = '2' THEN CONCAT(t10.ribbon_width, ' x ', t10.ribbon_length)
        ELSE 'The quantity is under 30'
    END AS Size 
    FROM 
        dispatch AS t1 
   JOIN 
        customer_master AS t2 ON t1.customer_id = t2.id 
    JOIN 
        location_master AS t3 ON t1.location_id = t3.id
        JOIN 
 jumbo_master AS t4 ON t1.jumbo_id = t4.id  
    JOIN 
 material_master AS t5 ON t4.material_id = t5.id    
    JOIN 
 user_master AS t6 ON t1.user_id = t6.id
    JOIN 
        core_master AS t7 ON t1.core_id = t7.id
    JOIN 
        orders AS t8 ON t1.order_id = t8.id
        LEFT JOIN label_master AS t9 ON t9.id = t1.label_id AND t8.order_type = 1
LEFT JOIN ribbon_master AS t10 ON t10.id = t1.label_id AND t8.order_type = 2
    WHERE 
        t1.status = 1        
        AND t1.ActiveStatus = 1 and t4.type like '%${radiouttons}%' ${query} ${dateFilter} ORDER BY t1.id DESC`)
        .then((res) => {
          console.log("Fetched data:", res);
          const modifiedData = res.data[0].rows.map((item, index) => {
            let size;
            if (item.type == "1") {
              const selected = LabelOptions?.find(
                (option) => option.id === item.label_id
              );
              size = `${selected?.label_width} x ${selected?.label_length} - (${selected?.label_ups} ups)`;
            } else {
              const selected = ribbonOptions?.find(
                (option) => option.id === item.label_id
              );
              size = `${selected?.ribbon_width} x ${selected?.ribbon_length}`;
            }
            return {
              ...item,
              sl_no: index + 1, // Adding Sl.No.
              dispatch_remarks: item.dispatch_remarks || "-", // Handle rejected_reason
              label_id: size,
              type: item.type == "1" ? "Label" : "Ribbon", // Add size based on the conditions
            };
          });

          setDownloadedData(modifiedData);
          setTimeout(() => {
            csvDownloadRef.current.link.click();
          }, 500);
        })
        .catch((error) => alert("Error happened"))
        .finally(() => setIsLoading(false));
    } else {
      setDownloadedData([]);
    }
  };

  const fetchData = (
    fromDate = null,
    toDate = null,
    radiouttons = "",
    query = ""
  ) => {
    setIsLoading(true);
    let dateFilter = "";
    if (fromDate && toDate) {
      dateFilter = `AND t1.ModifiedDate BETWEEN '${formatDate(
        fromDate
      )} 00:00:00' AND '${formatDate(toDate)} 23:59:59'`;
      ReadRecord(`SELECT 
        t1.*, 
        t2.customer_name, 
        t3.location_name, 
        t4.jumbo_type,
        t4.type,
        t5.material_name, 
        t6.username, 
        t7.core_size,
        t8.order_type,
        CASE
        WHEN t8.order_type = '1' THEN CONCAT(t9.label_width, ' x ', t9.label_length,' - ','(',t9.label_ups,' ups)')
        WHEN t8.order_type = '2' THEN CONCAT(t10.ribbon_width, ' x ', t10.ribbon_length)
        ELSE 'The quantity is under 30'
    END AS Size 
    FROM 
        dispatch AS t1 
   JOIN 
        customer_master AS t2 ON t1.customer_id = t2.id 
    JOIN 
        location_master AS t3 ON t1.location_id = t3.id
        JOIN 
 jumbo_master AS t4 ON t1.jumbo_id = t4.id  
    JOIN 
 material_master AS t5 ON t4.material_id = t5.id    
    JOIN 
 user_master AS t6 ON t1.user_id = t6.id
    JOIN 
        core_master AS t7 ON t1.core_id = t7.id
    JOIN 
        orders AS t8 ON t1.order_id = t8.id
        LEFT JOIN label_master AS t9 ON t9.id = t1.label_id AND t8.order_type = 1
LEFT JOIN ribbon_master AS t10 ON t10.id = t1.label_id AND t8.order_type = 2
    WHERE 
        t1.status = 1        
        AND t1.ActiveStatus = 1 and t4.type like '%${radiouttons}%' ${query} ${dateFilter} ORDER BY t1.id DESC`)
        .then((res) => {
          console.log("Fetched data:", res);
          setData(res.data[0].rows);
        })
        .catch((error) => alert("Error happened"))
        .finally(() => setIsLoading(false));
    }
  };

  const filteredData = filterStatus
    ? data.filter((item) => item.order_status === filterStatus)
    : data;

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      console.log("From Date:", dates[0]);
      console.log("To Date:", dates[1]);
      setFromDate(dates[0]);
      setToDate(dates[1]);
      // fetchData(dates[0], dates[1],radioutton);
      //fetchDataToDownload(dates[0], dates[1]);
    } else {
      console.log("Dates Cleared");
      setFromDate(null);
      setToDate(null);
      fetchData(null, null, radioutton);
    }
  };
  // const formatDate = (date) => {
  //     if (!date) return ''; // Return empty string if date is null or undefined

  //     // Ensure date is of type Date
  //     if (!(date instanceof Date)) {
  //       // If date is not a Date object, try to parse it
  //       date = new Date(date);
  //       // If parsing fails, return empty string
  //       if (isNaN(date.getTime())) return '';
  //     }

  //     // Format the date as DD-MM-YYYY
  //     const day = date.getDate().toString().padStart(2, '0');
  //     const month = (date.getMonth() + 1).toString().padStart(2, '0');
  //     const year = date.getFullYear();

  //     return `${day}-${month}-${year}`;
  //   };
  const options = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Label",
      value: "1",
    },
    {
      label: "Ribbon",
      value: "2",
    },
  ];
  const handleOrderType = (value) => {
    setRadioutton(value);
    if (fromDate && toDate) {
      fetchData(formatDate(fromDate), formatDate(toDate), value);
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: 1,
        pageSize: pagination.pageSize,
      }));
    }
  };
  const handleTableChange = (pagination, filters) => {
    console.log("filters", filters);

    let query = "";
    const filterClauses = [];

    if (
      filters?.material_name != null ||
      filters?.typee != null ||
      filters?.jumbo_type != null ||
      filters?.location_name != null ||
      filters?.customer_name != null ||
      filters?.Size != null
    ) {
      setFilteredInfo(filters);
    } else {
      setFilteredInfo({});
    }
    if (filters.material_name && filters.material_name.length > 0) {
      const MaerialInClause = filters.material_name
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (MaerialInClause) {
        filterClauses.push(`t5.material_name IN (${MaerialInClause})`);
      }
    }
    if (filters.typee && filters.typee.length > 0) {
      const ordertypeInClause = filters.typee
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (ordertypeInClause) {
        filterClauses.push(`t4.type IN (${ordertypeInClause})`);
      }
    }
    if (filters.Size && filters.Size.length > 0) {
      const labelAndRibbonInClause = filters.Size.filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (labelAndRibbonInClause) {
        filterClauses.push(`(
        (t8.order_type = '1' AND CONCAT(t9.label_width, ' x ', t9.label_length,' - ','(',t9.label_ups,' ups)') IN (${labelAndRibbonInClause})) OR
        (t8.order_type = '2' AND CONCAT(t10.ribbon_width, ' x ', t10.ribbon_length) IN (${labelAndRibbonInClause}))
    )`);
      }
    }
    if (filters.location_name && filters.location_name.length > 0) {
      const LocationInClause = filters.location_name
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (LocationInClause) {
        filterClauses.push(`t1.location_id IN (${LocationInClause})`);
      }
    }
    if (filters.customer_name && filters.customer_name.length > 0) {
      const CustomerNameInClause = filters.customer_name
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (CustomerNameInClause) {
        filterClauses.push(`t1.customer_id IN (${CustomerNameInClause})`);
      }
    }
    if (filters.jumbo_type && filters.jumbo_type.length > 0) {
      const JumboTypeInClause = filters.jumbo_type
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (JumboTypeInClause) {
        filterClauses.push(`t1.jumbo_id IN (${JumboTypeInClause})`);
      }
    }
    if (filterClauses.length > 0) {
      query = `AND ${filterClauses.join(" AND ")}`;
    }
    setOrderStatusQuery(query);
    return fetchData(fromDate, toDate, radioutton, query);
  };
  const clearFilters = () => {
    setFilteredInfo({});
    setOrderStatusQuery("");
    fetchData(formatDate(fromDate), formatDate(toDate), radioutton, "");
  };
  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p
          style={{
            color: "#333",
            marginRight: "auto",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          Dispatch Report
        </p>
        {filteredInfo && Object.keys(filteredInfo).length > 0 && (
          <Button
            onClick={clearFilters}
            style={{ borderColor: "red", color: "red" }}
          >
            Clear Filters
          </Button>
        )}
         &nbsp;
        <RangePicker
          value={[fromDate, toDate]}
          onChange={handleDateChange}
          style={{ width: 250 }}
        />
        &nbsp;
        <Radio.Group
          options={options}
          // onChange={(e) => handleTypeFilter(e)}
          onChange={(e) => handleOrderType(e.target.value)}
          value={radioutton}
          optionType="button"
          buttonStyle="solid"
        />
        &nbsp;
        <CSVLink
          headers={headers}
          data={downloadedData}
          filename={`Dispatch_Report_${
            fromDate ? formatDate(fromDate) : "from"
          }_to_${toDate ? formatDate(toDate) : "to"}.csv`}
          className="hidden"
          ref={csvDownloadRef}
          target="_blank"
        />
        &nbsp;
        <Button
          disabled={data?.length == 0}
          style={{ backgroundColor: "rgba(175, 253, 111, 0.26)" }}
          icon={<ExportOutlined style={{ color: "green", fontSize: 24 }} />}
          onClick={() =>
            fetchDataToDownload(fromDate, toDate, radioutton, OrderStatusQuery)
          }
        >
          Export to Excel
        </Button>
        &nbsp;
        <Button
          disabled={data?.length == 0}
          style={{ backgroundColor: "rgba(255, 0, 0, 0.1)" }}
          icon={<PrinterOutlined style={{ color: "brown", fontSize: 24 }} />}
          onClick={fetchDataToDownloadPDF}
        >
          Export to PDF
        </Button>
      </div>
      {isLoading ? (
        <LoadingOutlined style={{ marginTop: 20 }}>
          Loading report...
        </LoadingOutlined>
      ) : (
        <Table
          onChange={handleTableChange}
          className="custom-table custom-table-th"
          rowClassName={(record, index) =>
            index % 2 === 1 ? "even-row" : "odd-row"
          }
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            // onChange: (page, pageSize) => {
            //   setPagination((prevPagination) => ({
            //     ...prevPagination,
            //     current: page,
            //     pageSize: pageSize,
            //   }));
            // },
            total: pagination.total,
          }}
          size="small"
          bordered
          id="orders-table"
          dataSource={filteredData}
          columns={columns}
          locale={{
            emptyText: isLoading ? (
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            ) : (
              <Card style={{ background: "rgb(240, 240, 240)" }}>
                <SearchOutlined />
                &nbsp; No data available
              </Card>
            ),
          }}
        />
      )}
    </div>
  );
}

export default DispatchReport;
