import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Input, Select, message } from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  ReadRecord,
  CreateRecord,
  UpdateRecord,
} from "../../../Service/API/ApiService";

import "./Location.css";
import TextArea from "antd/es/input/TextArea";

const LocationMaster = () => {
  const [users, setUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editUserData, setEditUserData] = useState(null);
  const [ViewUserData, setViewUserData] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("Active");
  const [locationValidationStatus, setLocationValidationStatus] = useState("");
  const [locationHelpMessage, setLocationHelpMessage] = useState("");
  const addressCellStyle = {
    wordWrap: "break-word",
    whiteSpace: "normal",
    maxWidth: "200px",
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    apiCheck(pagination.current, pagination.pageSize, searchQuery);
    localStorage.setItem("Title", "Core");
    //userListData();
  }, [searchQuery]);

  async function apiCheck(page, pageSize, search = "") {
    let searchFilter = "";
    let offset = (page - 1) * pageSize;

    // Check if the search term is "active"
    if (search !== "") {
      // Handle the 'active' search case
      if (search.toLowerCase().trim() === "active") {
        searchFilter = `AND status = 1`;
      } else if (search.toLowerCase().trim() === "inactive") {
        searchFilter = `AND status = 0`;
      } else {
        searchFilter = `AND (location_name LIKE '%${search}%' 
                           OR address LIKE '%${search}%' 
                           OR id LIKE '%${search}%')`;
      }
      offset = 0; // Reset offset if there is a search term
    }

    // Construct the SQL queries
    let searchData = `SELECT * FROM location_master 
                      WHERE ActiveStatus = 1 ${searchFilter} 
                      ORDER BY status DESC, id DESC 
                      LIMIT ${pageSize} OFFSET ${offset}`;

    let searchDataCount = `SELECT COUNT(*) as TotalCount
                           FROM location_master 
                           WHERE ActiveStatus = 1 ${searchFilter}`;

    try {
      // Execute both queries concurrently
      const [data1, data2] = await Promise.all([
        ReadRecord(searchData).then((res) => {
          console.log("apiRequest1", res);
          return res;
        }),
        ReadRecord(searchDataCount).then((res) => {
          console.log("apiRequest2", res);
          return res;
        }),
      ]);

      // Handle the responses
      setUsers(data1.data[0].rows); // Update with the data from the search results
      let total = data2.data[0].rows[0].TotalCount; // Get the total count of results

      // Update pagination state
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: page,
        pageSize: pageSize,
        total: total, // Update total from your API response
      }));
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
    }
  }

  const columns = [
    {
      title: "S.No.",
      dataIndex: "serial",
      key: "serial",
      align: "center",
      render: (text, record, index) =>
        index + (pagination.current - 1) * pagination.pageSize + 1,
    },
    {
      title: "Location Name",
      dataIndex: "location_name",
      key: "Location",
      align: "center",
    },
    {
      title: "Address",
      dataIndex: "address",
      Tooltip: "address",
      key: "Address",
      align: "center",
      // width: 200,
      render: (address) => {
        const maxLength = 70;
        const displayText =
          address.length > maxLength
            ? `${address.substring(0, maxLength)}...`
            : address;
        return <span style={{fontSize:"12px"}}>{displayText}</span>;
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",

      render: (status) => <span style={{fontSize:"12px"}}>{status == 1 ? "Active" : "Inactive"}</span>,
    },

    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      align: "center",
      // width: 2000,
      render: (text, record) => (
        <>
          <Button
          size="small"
            icon={<EyeOutlined />}
            type="primary"
            style={{ backgroundColor: "#be991d" }}
            title="View"
            onClick={() => handleView(record)}
          ></Button>
          &nbsp;
          <Button
          size="small"
            icon={<EditOutlined />}
            style={{ backgroundColor: "#005c9f" }}
            type="primary"
            title="Edit"
            onClick={() => handleEdit(record)}
          ></Button>
          &nbsp;
          <Button
          size="small"
            icon={<DeleteOutlined />}
            type="primary"
            style={{ backgroundColor: "#d7524b" }}
            title="Delete"
            onClick={() => handleDelete(record)}
          ></Button>
        </>
      ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
    setEditUserData(null);
    setViewUserData(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setEditUserData("");
    setViewUserData("");
    setLocationHelpMessage("");
    setLocationValidationStatus("");
  };

  const onFinish = (values) => {
    if (values.address == undefined || values.address == "") {
      setLocationValidationStatus("error");
      setLocationHelpMessage("please fill address");
      return;
    }
    // If adding new data, check if core type and size already exist
    if (editUserData) {
      const existingCore = ReadRecord(`
        SELECT COUNT(*) AS count FROM location_master
        WHERE location_name = '${values.location_name}' and address='${values.address}'
        AND (status = 1 or status=0) AND ActiveStatus=1 AND id!='${editUserData.id}'
      `).then((res) => {
        console.log("core count", res.data[0]?.rows[0].count);
        if (res.data[0]?.rows[0].count == 0) {
          submitForm(values);
        } else {
          setLocationValidationStatus("error");
          setLocationHelpMessage(
            "Same address exsting with same Location name"
          );
          return;
        }
      });
    } else {
      const existingCore = ReadRecord(`
        SELECT COUNT(*) AS count FROM location_master
        WHERE location_name = '${values.location_name}' and address='${values.address}'
        AND (status = 1 or status=0) AND ActiveStatus=1
      `).then((res) => {
        console.log("core count", res.data[0]?.rows[0].count);
        if (res.data[0]?.rows[0].count == 0) {
          submitForm(values);
        } else {
          setLocationValidationStatus("error");
          setLocationHelpMessage(
            "Same address exsting with same Location name"
          );
          return;
        }
      });
    }
  };
  const submitForm = (values) => {
    let data = {
      location_name: values.location_name,
      address: values.address,
      status: values.status == "Active" ? 1 : 0,
    };
    if (editUserData) {
      const updatedData = { ...editUserData, ...values };
      updatedData.status = updatedData.status == "Active" ? 1 : 0;

      UpdateRecord("locationMaster", [updatedData])
        .then((res) => {
          message.success("Location updated successfully");

          apiCheck(pagination.current, pagination.pageSize, searchQuery);
          setIsModalVisible(false);
        })
        .catch((error) => {
          console.error("Error updating record:", error);
        });
    } else {
      CreateRecord("locationMaster", [data]).then((res) => {
        message.success("Location added successfully");

        console.log("created location data", res);
        setUsers([...users, values]);
        apiCheck(pagination.current, pagination.pageSize, searchQuery);
      });
    }
    setIsModalVisible(false);
    form.resetFields();
    handleCancel();
  };

  const handleView = (record) => {
    const recordWithStatusLabel = { ...record };

    recordWithStatusLabel.status =
      recordWithStatusLabel.status == 1 ? "Active" : "Inactive";

    setViewUserData(recordWithStatusLabel);
    setIsModalVisible(true);
    form.setFieldsValue(recordWithStatusLabel);
  };

  const handleEdit = (record) => {
    const recordWithStatusLabel = { ...record };

    recordWithStatusLabel.status =
      recordWithStatusLabel.status == 1 ? "Active" : "Inactive";
    console.log("record", recordWithStatusLabel);
    setEditUserData(recordWithStatusLabel);
    setIsModalVisible(true);
    form.setFieldsValue(recordWithStatusLabel);
  };

  const handleDelete = (record) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (isConfirmed) {
      console.log("Before deleting record", record.id);
      let data = {
        id: record.id,
        ActiveStatus: false,
      };
      UpdateRecord("deleteLocationMaster", [data]).then((res) => {
        message.info("Location has been deleted");

        apiCheck(pagination.current, pagination.pageSize, searchQuery);
        console.log("User record deleted", res);
      });
    }
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  return (
    <div>
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              color: "#333",
              marginRight: "auto",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            Location Master
          </p>
          <Input
            placeholder="Search"
            allowClear
            style={{ width: 350, margin: "0 16px" }}
            prefix={<SearchOutlined />}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Button type="primary" onClick={showModal} icon={<PlusOutlined />}>
            Add New Location
          </Button>
        </div>
        {/* <Table dataSource={filteredUsers} scroll={{y:400}} columns={columns} rowClassName={(record, index) => */}
        <Table
        className="custom-table custom-table-th"
          size="small"
          bordered
          dataSource={users}
          columns={columns}
          rowClassName={(record, index) =>
            index % 2 === 1 ? "even-row" : "odd-row"
          }
          pagination={{
            pageSize: pagination.pageSize,
            onChange: (page, pageSize) => {
              apiCheck(page, pageSize);
            },
            total: pagination.total,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </>
      <Modal
        title={
          editUserData
            ? "Edit Location"
            : ViewUserData
            ? "View Location "
            : "Create Location"
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
        maskClosable={false}
      >
        <hr></hr>
        &nbsp;
        <Form
          form={form}
          autoComplete="off"
          name="createUserForm"
          onFinish={onFinish}
          layout="horizontal"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 10 }}
          disabled={ViewUserData}
          id="locationform"
          className="locationform"
          initialValues={{ status: "Active" }}
        >
          <Form.Item
            label="Location Name"
            name="location_name"
            id="locationname"
            className="locationname"
            rules={[{ required: true, message: "Please enter location!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span
                  title="address"
                  style={{ color: "#ff4d4f", fontSize: 16 }}
                >
                  *
                </span>{" "}
                Address
              </span>
            }
            name="address"
            id="locationaddress"
            className="locationaddress"
            validateStatus={locationValidationStatus} // Validate status
            help={locationHelpMessage} // Help message
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            id="status"
            className="status"
            rules={[{ required: true, message: "Please select status!" }]}
          >
            <Select
              onChange={(value) => setSelectedStatus(value)}
              placeholder="Select"
            >
              <Select.Option value="Active">Active</Select.Option>
              <Select.Option value="Inactive">Inactive</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            {ViewUserData ? null : (
              <>
                <Button
                  type="primary"
                  htmlType="submit"
                  id="customerformsubmit"
                  className="customerformsubmit"
                >
                  {editUserData ? "Update" : "Add "}
                </Button>
                <Button style={{ marginLeft: 8 }} onClick={handleCancel}>
                  Cancel
                </Button>
              </>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default LocationMaster;
