import { useEffect, useRef, useState } from "react";
import { ReadRecord } from "../../Service/API/ApiService";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import {
  Button,
  Table,
  DatePicker,
  Flex,
  Spin,
  Card,
  theme,
  Radio,
} from "antd";
import {
  LoadingOutlined,
  ExportOutlined,
  SearchOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import formatDate from "../../Components/formatDate";
import logo from "../../Assests/harnetlogo.png";
import moment from "moment";

const headers = [
  { label: "Sl.No.", key: "sl_no" }, // Adding Sl.No. header
  { label: "Order ID", key: "order_number" },
  { label: "Order Date", key: "order_date" },
  { label: "Customer", key: "customer_name" },
  { label: "Jumbo Type", key: "jumbo_type" },
  { label: "Material Type", key: "material_name" },
  { label: "Size", key: "label_size" },
  { label: "Location", key: "location_name" },
  { label: "Order Quantity", key: "order_quantity" },
  { label: "Order Status", key: "order_status" },
  { label: "Ordered Dispatched Date", key: "CreatedDate" },
  { label: "Rejected Reason", key: "rejected_reason" },
];

const Report = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const [data, setData] = useState([]);
  const [downloadedData, setDownloadedData] = useState([]);
  const csvDownloadRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState(null);

  // console.log("startOfMonth", start);
  // console.log("endOfMonth", end);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [radioutton, setRadioutton] = useState("");
  const [ribbonOptions, setRibbonOptions] = useState([]);
  const [LabelOptions, setLabeldetails] = useState(null);
  const [customerOptions, setCustomerDetails] = useState(null);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [jumboData, setJumboData] = useState(null);
  const [MaterialOptions, setMaterialDetails] = useState(null);
  const [LocationOptions, setLocationDetails] = useState(null);
  const [OrderStatusQuery, setOrderStatusQuery] = useState("");
  const [labelAndRibbonData, setlabelAndRibbonData] = useState(null);
  const { RangePicker } = DatePicker;
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const exportPdf = useRef();
  useEffect(() => {
    const nowIST = dayjs().tz("Asia/Kolkata");
    const startOfMonth = nowIST.startOf("month");
    const endOfMonth = nowIST.endOf("month");

    console.log("startOfMonth", startOfMonth);
    setFromDate(startOfMonth);
    setToDate(endOfMonth);
    getCustomerDetails();
    getJumboData("");
    getMaterialDetail();
    getLocationDetails();
    getlabelAndRibbonComboDetails();
  }, []);
  function getlabelAndRibbonComboDetails() {
    ReadRecord(
      `SELECT 
        label_master.id AS id,
        '1' AS type,
         CONCAT(label_master.label_width, ' x ', label_master.label_length,' - ','(',label_master.label_ups,' ups)') AS Size
    FROM
label_master
    UNION ALL SELECT 
        ribbon_master.id AS id,
        '2' AS type,
        CONCAT(ribbon_master.ribbon_width, ' x ', ribbon_master.ribbon_length) AS Size
    FROM
        ribbon_master`
    ).then((res) => {
      setlabelAndRibbonData(res.data[0]?.rows);
    });
  }
  function getLocationDetails() {
    ReadRecord(
      `select * from location_master where status=1 and ActiveStatus=1`
    ).then((res) => {
      setLocationDetails(res.data[0]?.rows);
    });
  }
  function getMaterialDetail() {
    ReadRecord(
      `SELECT id,material_name FROM material_master WHERE status = 1 and ActiveStatus = 1`
    ).then((res) => {
      setMaterialDetails(res.data[0]?.rows);
    });
  }
  function getJumboData(value) {
    ReadRecord(
      `SELECT id,jumbo_type FROM jumbo_master WHERE status = 1 and ActiveStatus = 1 and type like '%${value}%' order by id desc;`
    ).then((res) => {
      setJumboData(res.data[0]?.rows || []);
    });
  }
  function getCustomerDetails() {
    ReadRecord(
      `select * from customer_master where status=1 and ActiveStatus=1`
    ).then((res) => {
      setCustomerDetails(res.data[0]?.rows);
    });
  }

  useEffect(() => {
    getLabelDetails();
    fetchRibbonOptions();
    if (fromDate && toDate) {
      fetchData(
        formatDate(fromDate),
        formatDate(toDate),
        radioutton,
        OrderStatusQuery
      );
      // fetchDataToDownload(fromDate, toDate);
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: 1,
        pageSize: pagination.pageSize,
      }));
    } else {
      setData([]);
      setIsLoading(false);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    console.log("datadata", data.length);
  }, [data]);
  function getLabelDetails() {
    ReadRecord(
      `SELECT id,label_length,label_width,label_measure,label_ups FROM label_master WHERE status = 1 and ActiveStatus = 1`
    ).then((res) => {
      setLabeldetails(res.data[0]?.rows);
    });
  }
  const fetchRibbonOptions = async () => {
    try {
      const response = await ReadRecord(
        `SELECT id,ribbon_length,ribbon_width,ribbon_measure FROM ribbon_master WHERE status = 1 and ActiveStatus = 1`
      );
      setRibbonOptions(response.data[0]?.rows || []);
    } catch (error) {
      console.error("Error fetching Label options:", error);
    }
  };
  const fetchDataToDownloadPDF = () => {
    console.log("downloading data", data);
    setIsLoading(true);

    if (fromDate && toDate) {
      const doc = new jsPDF({
        orientation: "landscape",
      });
      doc.setFontSize(18); // Set font size for the title
      doc.text("Harnet India Private Ltd.,", 110, 12);
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 139); // Dark blue color
      doc.text("ORDER DETAILS :", 15, 15);
      const logoImg = logo;
      doc.addImage(
        logoImg,
        "PNG",
        doc.internal.pageSize.getWidth() - 35,
        3,
        20,
        15
      );

      const tableStyles = {
        theme: "grid",
        styles: {
          fontSize: 8, // Adjust the font size here
          halign: "center",
        },
        margin: { top: 20 },
        columnStyles: {
          rejected_reason: { cellWidth: 50 },
          label_size: { cellWidth: 30 },
          default: { cellWidth: "auto" },
        },
      };

      // Adjusting the columns to include dataKey and styling
      const tableColumns = columns.map((col) => ({
        ...col,
        dataKey: col.dataIndex,
      }));

      // Add serial numbers to the data from filteredData
      const dataWithSerialNumbers = filteredData.map((row, index) => ({
        ...row,
        serial: index + 1, // Add the serial number manually
        rejected_reason: row.rejected_reason ? row.rejected_reason : "-",
        label_size:
          row.order_type == "1"
            ? LabelOptions?.find((option) => option.id === row.label_size)
              ? `${
                  LabelOptions.find((option) => option.id === row.label_size)
                    ?.label_width
                } x ${
                  LabelOptions.find((option) => option.id === row.label_size)
                    ?.label_length
                } - (${
                  LabelOptions.find((option) => option.id === row.label_size)
                    ?.label_ups
                } ups)`
              : "-" // Default value in case no matching label is found
            : ribbonOptions?.find((option) => option.id === row.label_size)
            ? `${
                ribbonOptions.find((option) => option.id === row.label_size)
                  ?.ribbon_width
              } x ${
                ribbonOptions.find((option) => option.id === row.label_size)
                  ?.ribbon_length
              }`
            : "-",
      }));

      // Generate the table with the provided styles
      doc.autoTable(tableColumns, dataWithSerialNumbers, tableStyles);

      const currentDate = new Date();
      const fileName = `order_${formatDate(fromDate)}_to_${formatDate(
        toDate
      )}.pdf`;

      // Save the PDF
      doc.save(fileName);
      setIsLoading(false);
    } else {
      setDownloadedData([]);
    }
  };

  const fetchDataToDownload = (
    fromDate = null,
    toDate = null,
    radiouttons = "",
    query = ""
  ) => {
    setIsLoading(true);
    let dateFilter = "";
    if (fromDate && toDate) {
      dateFilter = `AND t1.order_date BETWEEN '${fromDate}' AND '${toDate}'`;
      ReadRecord(`SELECT 
        t1.*, 
        t2.customer_name, 
        t3.location_name,
        t7.material_name,
        t7.material_name AS material_id,
        t5.jumbo_type,
        t5.type,
        t6.username,
         CASE
        WHEN t1.order_type = '1' THEN CONCAT(t8.label_width, ' x ', t8.label_length,' - ','(',t8.label_ups,' ups)')
        WHEN t1.order_type = '2' THEN CONCAT(t9.ribbon_width, ' x ', t9.ribbon_length)
        ELSE 'The quantity is under 30'
    END AS Size  
      FROM 
        orders AS t1 
      JOIN 
        customer_master AS t2 ON t1.customer_id = t2.id 
      JOIN 
        location_master AS t3 ON t1.location_id = t3.id 
      JOIN 
        jumbo_master AS t5 ON t1.jumbo_id = t5.id 
      JOIN 
        user_master AS t6 ON t1.user_id = t6.id        
      JOIN 
        material_master AS t7 ON t5.material_id = t7.id
        LEFT JOIN label_master AS t8 ON t8.id = t1.label_size AND t1.order_type = 1
LEFT JOIN ribbon_master AS t9 ON t9.id = t1.label_size AND t1.order_type = 2
      WHERE 
        t1.status = 1 
        AND t1.ActiveStatus = 1 and order_type like '%${radiouttons}%' ${query}  ${dateFilter}`)
        .then((res) => {
          const modifiedData = res.data[0].rows.map((item, index) => {
            let size;
            if (item.order_type == "1") {
              const selected = LabelOptions?.find(
                (option) => option.id === item.label_size
              );
              size = `${selected?.label_width} x ${selected?.label_length} - (${selected?.label_ups} ups)`;
            } else {
              const selected = ribbonOptions?.find(
                (option) => option.id === item.label_size
              );
              size = `${selected?.ribbon_width} x ${selected?.ribbon_length}`;
            }
            return {
              ...item,
              sl_no: index + 1, // Adding Sl.No.
              rejected_reason: item.rejected_reason || "-", // Handle rejected_reason
              label_size: size, // Add size based on the conditions
            };
          });

          setDownloadedData(modifiedData);

          setTimeout(() => {
            csvDownloadRef.current.link.click();
          }, 500);
        })
        .catch((error) => alert("Error happened"))
        .finally(() => setIsLoading(false));
    } else {
      setDownloadedData([]);
    }
  };

  const fetchData = (
    fromDate = null,
    toDate = null,
    radiouttons = "",
    query = ""
  ) => {
    setIsLoading(true);
    let dateFilter = "";
    if (fromDate && toDate) {
      dateFilter = `AND t1.order_date BETWEEN '${fromDate}' AND '${toDate}'`;
      ReadRecord(`SELECT 
        t1.*, 
        t2.customer_name, 
        t3.location_name,
        t7.material_name,
        t7.material_name AS material_id,
        t5.jumbo_type,
        t5.type,
        t6.username,
        CASE
        WHEN t1.order_type = '1' THEN CONCAT(t8.label_width, ' x ', t8.label_length,' - ','(',t8.label_ups,' ups)')
        WHEN t1.order_type = '2' THEN CONCAT(t9.ribbon_width, ' x ', t9.ribbon_length)
        ELSE 'The quantity is under 30'
    END AS Size 
    FROM 
        orders AS t1 
    JOIN 
        customer_master AS t2 ON t1.customer_id = t2.id 
    JOIN 
        location_master AS t3 ON t1.location_id = t3.id 
    JOIN 
        jumbo_master AS t5 ON t1.jumbo_id = t5.id 
    JOIN 
        user_master AS t6 ON t1.user_id = t6.id        
    JOIN 
        material_master AS t7 ON t5.material_id = t7.id
        LEFT JOIN label_master AS t8 ON t8.id = t1.label_size AND t1.order_type = 1
LEFT JOIN ribbon_master AS t9 ON t9.id = t1.label_size AND t1.order_type = 2
    WHERE 
        t1.status = 1 
        AND t1.ActiveStatus = 1 and t1.order_type like '%${radiouttons}%' ${query} ${dateFilter} ORDER BY t1.id DESC`)
        .then((res) => {
          console.log("Fetched data:", res);
          setData(res.data[0].rows);
        })
        .catch((error) => alert("Error happened"))
        .finally(() => setIsLoading(false));
    }
  };

  const columns = [
    {
      title: "Sl.No.",
      dataIndex: "serial",
      key: "serial",
      align: "center",
      width: 10,
      render: (text, record, index) =>
        index + (pagination.current - 1) * pagination.pageSize + 1,
    },
    {
      title: "Order ID",
      dataIndex: "order_number",
      key: "order1",
      align: "center",
      width: 20,
    },
    {
      title: "Order Date",
      dataIndex: "CreatedDate",
      key: "CreatedDate",
      align: "center",
      width: 100,
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
      align: "center",
      width: 10,
      filters: customerOptions?.map((item) => ({
        text: item.customer_name,
        value: item.id,
      })),
      filteredValue: filteredInfo.customer_name || null,
    },
    {
      title: "Jumbo Type",
      dataIndex: "jumbo_type",
      key: "jumbo_type",
      width: 50,
      align: "center",
      filters: jumboData?.map((item) => ({
        text: item.jumbo_type,
        value: item.id,
      })),
      filteredValue: filteredInfo.jumbo_type || null,
    },
    {
      title: "Material Type",
      dataIndex: "material_name",
      key: "material_name",
      width: 50,
      align: "center",
      filters: MaterialOptions?.map((item) => ({
        text: item.material_name,
        value: item.material_name,
      })),
      filteredValue: filteredInfo.material_name || null,
      onFilter: (value, record) => record.material_name === value,
    },
    {
      title: "Size",
      dataIndex: "Size",
      key: "Size",
      width: 80,
      align: "center",
      filters: labelAndRibbonData?.map((item) => ({
        text: item.Size,
        value: item.Size,
      })),
      filteredValue: filteredInfo.Size || null,
      onFilter: (value, record) => record.Size === value,
    },
    {
      title: "Location",
      dataIndex: "location_name",
      key: "location_name",
      align: "center",
      width: 50,
      filters: LocationOptions?.map((item) => ({
        text: item.location_name,
        value: item.id,
      })),
      filteredValue: filteredInfo.location_name || null,
      // onFilter: (value, record) => record.location_name === value,
    },

    {
      title: "Order Status",
      dataIndex: "order_status",
      key: "orderStatus",
      align: "center",
      width: 50,
      filters: [
        { text: "Awaiting Approval", value: "Awaiting Approval" },
        { text: "Approved", value: "Approved" },
        { text: "Dispatched", value: "Dispatched" },
        { text: "In Progress", value: "In Progress" },
        { text: "Production Completed", value: "Production Completed" },
        { text: "Rejected", value: "Rejected" },
        { text: "Production Rejected", value: "Production Rejected" },
      ],
      filteredValue: filteredInfo.orderstatus || null,
      onFilter: (value, record) => record.order_status === value,
    },
    {
      title: "Order Quantity",
      dataIndex: "order_quantity",
      key: "orderI6",
      align: "center",
      width: 50,
    },
    {
      title: "Order Dispatched Date",
      dataIndex: "ModifiedDate",
      key: "orderDispatchedDate",
      align: "center",
      width: 50,
    },
    // { title: 'Order Remarks', dataIndex: 'order_remarks', key: 'orderI4', align: 'center',width: 50, },
    {
      title: "Rejected Reason",
      dataIndex: "rejected_reason",
      key: "rejected_reason",
      align: "center",
      width: 50,
      render: (record) => {
        return record ? record : "-";
      },
    },
  ];

  const filteredData = filterStatus
    ? data.filter((item) => item.order_status === filterStatus)
    : data;

  const handleDateChange = (dates) => {
    // console.log("dates", dates);
    if (dates && dates.length === 2) {
      // console.log("From Date:", dates[0]);
      // console.log("To Date:", dates[1]);
      setFromDate(dates[0]);
      setToDate(dates[1]);
      // fetchData(formatDate(dates[0]), formatDate(dates[1]), radioutton);
      //fetchDataToDownload(dates[0], dates[1]);
    } else {
      console.log("Dates Cleared");
      setFromDate(null);
      setToDate(null);
      fetchData(null, null, radioutton, OrderStatusQuery);
    }
  };
  const options = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Label",
      value: "1",
    },
    {
      label: "Ribbon",
      value: "2",
    },
  ];
  const handleOrderType = (value) => {
    getJumboData(value);
    setRadioutton(value);
    if (fromDate && toDate) {
      fetchData(
        formatDate(fromDate),
        formatDate(toDate),
        value,
        OrderStatusQuery
      );
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: 1,
        pageSize: pagination.pageSize,
      }));
    }
  };
  const handleTableChange = (pagination, filters) => {
    console.log("filters", filters);
    let query = "";
    const filterClauses = [];
    if (
      filters?.customer_name != null ||
      filters?.jumbo_type != null ||
      filters?.material_name != null ||
      filters?.location_name != null ||
      filters?.orderstatus != null ||
      filters?.Size != null
    ) {
      setFilteredInfo(filters);
    } else {
      setFilteredInfo({});
    }
    if (filters.customer_name && filters.customer_name.length > 0) {
      const CustomerNameInClause = filters.customer_name
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (CustomerNameInClause) {
        filterClauses.push(`t1.customer_id IN (${CustomerNameInClause})`);
      }
    }
    if (filters.Size && filters.Size.length > 0) {
      const labelAndRibbonInClause = filters.Size.filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (labelAndRibbonInClause) {
        filterClauses.push(`(
        (t1.order_type = '1' AND CONCAT(t8.label_width, ' x ', t8.label_length,' - ','(',t8.label_ups,' ups)') IN (${labelAndRibbonInClause})) OR
        (t1.order_type = '2' AND CONCAT(t9.ribbon_width, ' x ', t9.ribbon_length) IN (${labelAndRibbonInClause}))
    )`);
      }
    }
    if (filters.orderstatus && filters.orderstatus.length > 0) {
      const orderStatusInClause = filters.orderstatus
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (orderStatusInClause) {
        filterClauses.push(`t1.order_status IN (${orderStatusInClause})`);
      }
    }
    if (filters.location_name && filters.location_name.length > 0) {
      const LocationInClause = filters.location_name
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (LocationInClause) {
        filterClauses.push(`t1.location_id IN (${LocationInClause})`);
      }
    }
    if (filters.jumbo_type && filters.jumbo_type.length > 0) {
      const JumboTypeInClause = filters.jumbo_type
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (JumboTypeInClause) {
        filterClauses.push(`t1.jumbo_id IN (${JumboTypeInClause})`);
      }
    }
    if (filters.material_name && filters.material_name.length > 0) {
      const MaerialInClause = filters.material_name
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (MaerialInClause) {
        filterClauses.push(`t7.material_name IN (${MaerialInClause})`);
      }
    }
    if (filterClauses.length > 0) {
      query = `AND ${filterClauses.join(" AND ")}`;
    }
    setOrderStatusQuery(query);
    return fetchData(
      formatDate(fromDate),
      formatDate(toDate),
      radioutton,
      query
    );
  };
  const clearFilters = () => {
    setFilteredInfo({});
    setOrderStatusQuery("");
    fetchData(formatDate(fromDate), formatDate(toDate), radioutton, "");
  };
  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p
          style={{
            color: "#333",
            marginRight: "auto",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          Order Report
        </p>
        {filteredInfo && Object.keys(filteredInfo).length > 0 && (
          <Button
            onClick={clearFilters}
            style={{ borderColor: "red", color: "red" }}
          >
            Clear Filters
          </Button>
        )}
         &nbsp;
        <RangePicker
          // defaultValue={[defaultStart, defaultEnd]}
          value={[fromDate, toDate]}
          onChange={handleDateChange}
          style={{ width: 250 }}
        />
        &nbsp;
        <Radio.Group
          options={options}
          // onChange={(e) => handleTypeFilter(e)}
          onChange={(e) => handleOrderType(e.target.value)}
          value={radioutton}
          optionType="button"
          buttonStyle="solid"
        />
        &nbsp;
        <CSVLink
          headers={headers}
          data={downloadedData}
          filename={`Orders_Report_${
            fromDate ? formatDate(fromDate) : "from"
          }_to_${toDate ? formatDate(toDate) : "to"}.csv`}
          className="hidden"
          ref={csvDownloadRef}
          target="_blank"
        />
        &nbsp;
        <Button
          disabled={data?.length == 0}
          style={{ backgroundColor: "rgba(175, 253, 111, 0.26)" }}
          icon={<ExportOutlined style={{ color: "green", fontSize: 24 }} />}
          onClick={() =>
            fetchDataToDownload(
              formatDate(fromDate),
              formatDate(toDate),
              radioutton,
              OrderStatusQuery
            )
          }
        >
          Export to Excel
        </Button>
        &nbsp;
        <Button
          disabled={data?.length == 0}
          style={{ backgroundColor: "rgba(255, 0, 0, 0.1)" }}
          icon={<PrinterOutlined style={{ color: "brown", fontSize: 24 }} />}
          onClick={fetchDataToDownloadPDF}
        >
          Export to PDF
        </Button>
      </div>
      {isLoading ? (
        <LoadingOutlined style={{ marginTop: 20 }}>
          Loading report...
        </LoadingOutlined>
      ) : (
        <Table
          onChange={handleTableChange}
          rowClassName={(record, index) =>
            index % 2 === 1 ? "even-row" : "odd-row"
          }
          className="custom-table custom-table-th"
          size="small"
          bordered
          id="orders-table"
          dataSource={filteredData}
          columns={columns}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            // onChange: (page, pageSize) => {
            //   setPagination((prevPagination) => ({
            //     ...prevPagination,
            //     current: page,
            //     pageSize: pageSize,
            //   }));
            // },
            total: pagination.total,
          }}
          locale={{
            emptyText: isLoading ? (
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            ) : (
              <Card style={{ background: "rgb(240, 240, 240)" }}>
                <SearchOutlined />
                &nbsp; No data available
              </Card>
            ),
          }}
        />
      )}
    </div>
  );
};

export default Report;
