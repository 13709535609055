import react from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  Tooltip,
  InputNumber,
  Row,
  Col,
  Popconfirm,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
const OrderForm = ({
  form,
  onFinish,
  loggedinuser,
  viewOrders,
  JumboOptions,
  handleMaterialChange,
  customerOptions,
  LocationOptions,
  LabelOptions,
  selectedCoreId,
  formatDate,
  orderDate,
  MaterialOptions,
  handleLabelChange,
  quantityError,
  handleQuantityChange,
  TextArea,
  createOrders,
  editOrders,
  handleApprove,
  handleDispatch,
  handleReject,
  isRejectModalVisible,
  handleNewCancel,
  handleRejectSubmit,
  handleMoveToProduction,
  numericError,
  selectedLabelId,
  handleJumboTypeChange,
  typeFilter,
  ribbonOptions,
  isDispatchModalVisible,
  handleDispatchCancel,
  otherMaterials,
  addRow,
  rows,
  setRows,
  handleOtherMaterialChange,
  handleOtherQuantityChange,
  handleDeleteRow,
  currentUrl,
  isMoveToProduction,
  handleOtherMatCancel,
  setOtherMatInput,
  otherMatInput,
  AvailableQuantityForProduction,
  setOrderSize,
  OrderSize,
  handleOrderSize,
  harnetRoleAccess,
  userRoleHarnet,
}) => {
  return (
    <Form
      form={form}
      name="createorderform"
      onFinish={onFinish}
      layout="horizontal"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      id="orderform"
      className="orderform"
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="User name"
            name="username"
            id="username"
            className="usernname"
            initialValue={loggedinuser}
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Order Date"
            name="order_date"
            id="order_date"
            disabled={viewOrders}
            className="order_date"
            initialValue={formatDate(orderDate)}
          >
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Jumbo type"
            name="jumbo_id"
            id="jum_name"
            className="jum_name"
            rules={[{ required: true, message: "Please select Jumbo_type!" }]}
          >
            <Select
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select"
              // options={JumboOptions && JumboOptions.map(loc => ({ value: loc.id, label: loc.jumbo_type }))}
              disabled={
                viewOrders ||
                typeFilter === "" ||
                (editOrders && editOrders?.order_status != "Awaiting Approval")
              }
              onChange={handleJumboTypeChange}
            >
              {JumboOptions &&
                JumboOptions.map((loc) => (
                  <Select.Option key={loc.id} value={loc.id}>
                    {loc.jumbo_type}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Material type"
            name="material_id"
            id="mat_name"
            className="mat_name"
            rules={[{ required: true, message: "Please select Material!" }]}
          >
            {/* <Select
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select"
              onChange={handleMaterialChange}
              disabled={viewOrders}
            >
              {MaterialOptions &&
                MaterialOptions.map((loc) => (
                  <Select.Option key={loc.id} value={loc.id}>
                    {loc.material_name}
                  </Select.Option>
                  // { value: loc.id, label: loc.material_name }
                ))}
            </Select> */}
            <Input
              readOnly
              disabled={viewOrders || typeFilter === "" || editOrders}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Customer"
            name="customer_id"
            id="cust_name"
            className="cust_name"
            rules={[{ required: true, message: "Please select Customer!" }]}
          >
            <Select
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select"
              // options={customerOptions && customerOptions.map(cus => ({ value: cus.id, label: cus.customer_name }))}
              disabled={viewOrders || typeFilter === "" || editOrders}
            >
              {customerOptions &&
                customerOptions.map((cus) => (
                  <Select.Option key={cus.id} value={cus.id}>
                    {cus.customer_name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Location"
            name="location_id"
            id="loc_name"
            className="loc_name"
            rules={[{ required: true, message: "Please select Location!" }]}
          >
            <Select
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select"
              // options={LocationOptions && LocationOptions.map(loc => ({ value: loc.id, label: loc.location_name }))}
              disabled={viewOrders || typeFilter === "" || editOrders}
            >
              {LocationOptions &&
                LocationOptions.map((loc) => (
                  <Select.Option key={loc.id} value={loc.id}>
                    {loc.location_name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={<Tooltip title="Expected time of Arrival">ETA</Tooltip>}
            name="expected_delivery_date"
            id="exp_date"
            className="exp_date"
            // rules={[{ required: true, message: 'Please select ETA!' }]}
          >
            <Input
              type="date"
              format="YYYY-MM-DD"
              disabled={viewOrders || typeFilter === ""}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Per Roll"
            name="per_roll"
            id="per_roll"
            className="per_roll"
          >
            <InputNumber
              disabled={viewOrders || typeFilter === ""}
              min={1}
              type="number"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Row gutter={0} align="middle">
            <Col flex="auto">
              <Form.Item
                label={typeFilter === "1" ? "Label Size" : "Ribbon Size"}
                name="label_size"
                id="label_size"
                className="label_size"
                wrapperCol={{ span: 14 }}
                rules={[
                  { required: true, message: "Please select Label Size" },
                ]}
              >
                <Select
                  onChange={handleLabelChange}
                  showSearch
                  allowClear
                  filterOption={(input, option) => {
                    const optionText = option.children.join(" ").toLowerCase();
                    return optionText.includes(input.toLowerCase());
                  }}
                  placeholder="Select"
                  disabled={viewOrders || typeFilter === "" || editOrders}
                >
                  {typeFilter === "1"
                    ? LabelOptions &&
                      LabelOptions.map((label) => (
                        <Select.Option key={label.id} value={label.id}>
                          {label.label_width} x {label.label_length}{" "}
                          {label.label_measure}
                          {" - ("}
                          {label.label_ups}
                          {" ups)"}
                        </Select.Option>
                      ))
                    : ribbonOptions &&
                      ribbonOptions.map((label) => (
                        <Select.Option key={label.id} value={label.id}>
                          {label.ribbon_width} x {label.ribbon_length}{" "}
                          {/* {label.ribbon_measure} */}
                        </Select.Option>
                      ))}
                </Select>
              </Form.Item>
            </Col>
            {!viewOrders && !editOrders && (
              <Col flex="none">
                <Form.Item>
                  <Tooltip title="Add Quantity" placement="rightBottom">
                    <Popconfirm
                      title={
                        typeFilter === "1" ? "Label Master" : "Ribbon Master"
                      }
                      onConfirm={() => handleOrderSize()}
                      okText="Add In Master"
                      cancelText="Cancel"
                      description={
                        <>
                          <span>
                            <Form
                              layout="vertical"
                              labelCol={{ span: 10 }}
                              wrapperCol={{ span: 16 }}
                            >
                              <Row>
                                <Col>
                                  <Form.Item label="Width :">
                                    <Input
                                      autoComplete="off"
                                      value={OrderSize?.width}
                                      type="number"
                                      onChange={(e) => {
                                        setOrderSize((pre) => ({
                                          ...pre,
                                          width: e.target.value,
                                        }));
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col>
                                  <Form.Item label="Length :">
                                    <Input
                                      autoComplete="off"
                                      value={OrderSize?.length}
                                      type="number"
                                      onChange={(e) => {
                                        setOrderSize((pre) => ({
                                          ...pre,
                                          length: e.target.value,
                                        }));
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                {typeFilter == "1" && (
                                  <Col>
                                    <Form.Item label="Ups :">
                                      <Input
                                        autoComplete="off"
                                        value={OrderSize?.label_ups}
                                        type="number"
                                        onChange={(e) => {
                                          setOrderSize((pre) => ({
                                            ...pre,
                                            label_ups: e.target.value,
                                          }));
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                )}
                              </Row>
                            </Form>
                          </span>
                        </>
                      }
                    >
                      {(harnetRoleAccess && harnetRoleAccess.includes("18")) ||
                      (userRoleHarnet && userRoleHarnet === "1") ? (
                        <Button
                          disabled={
                            viewOrders || typeFilter === "" || editOrders
                          }
                          type="primary"
                          icon={<PlusOutlined />}
                        />
                      ) : (
                        ""
                      )}
                    </Popconfirm>
                  </Tooltip>
                </Form.Item>
                {/* <Button
                  disabled={viewOrders || typeFilter === "" || editOrders}
                  type="primary"
                  icon={<PlusOutlined />}
                /> */}
              </Col>
            )}
          </Row>
        </Col>

        <Col span={12}>
          {typeFilter == 1 && (
            <Form.Item
              label="Ups"
              name="label_ups"
              id="label_ups"
              className="label_ups"
            >
              <InputNumber
                disabled={viewOrders || typeFilter === ""}
                min={1}
                type="number"
              />
            </Form.Item>
          )}
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Core size"
            name="core_size"
            id="core_type"
            className="core_type"
            rules={[{ required: true, message: "Please select Core type" }]}
          >
            <Select
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select"
              // options={selectedCoreId && selectedCoreId.map(cus => ({ value: cus.id, label: cus.core_size }))}
              disabled={viewOrders || typeFilter === "" || editOrders}
            >
              {selectedCoreId &&
                selectedCoreId.map((record) => (
                  <Select.Option key={record.id} value={record.id}>
                    {record.core_type +
                      " - " +
                      record.core_dia +
                      " " +
                      record.core_dia_measure +
                      " x " +
                      record.core_length +
                      " " +
                      record.core_length_measure}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={
              <span>
                <span
                  title="quantity"
                  style={{ color: "#ff4d4f", fontSize: 16 }}
                >
                  *
                </span>{" "}
                Quantity
              </span>
            }
            name="order_quantity"
            id="ord_qun"
            className="ord_qun"
            validateStatus={quantityError || numericError ? "error" : ""}
            help={numericError || quantityError || ""}
          >
            <Input
              onChange={handleQuantityChange}
              disabled={viewOrders || typeFilter === ""}
              min={1}
              autoComplete="off"
              readOnly={!selectedLabelId}
              type="number"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Order Remarks"
            name="order_remarks"
            id="ord_remarks"
            className="ord_remarks"
            style={{ height: 20 }}
          >
            <TextArea disabled={viewOrders || typeFilter === ""} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          {!createOrders &&
          !editOrders &&
          viewOrders.order_status == "Rejected" ? (
            <Form.Item
              label="Rejected Reason"
              name="rejected_reason"
              id="ord_remarks1"
              className="ord_remarks1"
            >
              <TextArea disabled={viewOrders} />
            </Form.Item>
          ) : null}
        </Col>
        <Col span={12}>
          {!createOrders &&
          !editOrders &&
          viewOrders.order_status == "Production Rejected" ? (
            <Form.Item
              label="Production Reject"
              name="production_order_rejected_reason"
              id="production_order_rejected_reason"
              className="production_order_rejected_reason"
            >
              <TextArea disabled={viewOrders} />
            </Form.Item>
          ) : null}
        </Col>
      </Row>
      {currentUrl?.includes("dashboard")
        ? null
        : viewOrders && (
            <Form.Item wrapperCol={{ offset: 7, span: 100 }}>
              {(harnetRoleAccess && harnetRoleAccess.includes("14")) ||
              (userRoleHarnet && userRoleHarnet === "1")
                ? ![
                    "Rejected",
                    "Dispatched",
                    "Approved",
                    "In Progress",
                  ].includes(viewOrders.order_status) && (
                    <Button
                      type="primary"
                      style={{ background: "green" }}
                      disabled={[
                        "Rejected",
                        "Dispatched",
                        "Approved",
                        "In Progress",
                      ].includes(viewOrders.order_status)}
                      onClick={handleApprove}
                      id="ordertoproduction"
                      className="ordertoproduction"
                    >
                      Approve
                    </Button>
                  )
                : ""}
              &nbsp;&nbsp;
              {(harnetRoleAccess && harnetRoleAccess.includes("15")) ||
              (userRoleHarnet && userRoleHarnet === "1")
                ? ![
                    "Awaiting Approval",
                    "Rejected",
                    "Dispatched",
                    "In Progress",
                    "Production Completed",
                    "Production Rejected",
                  ].includes(viewOrders.order_status) && (
                    <Button
                      type="primary"
                      disabled={[
                        "Awaiting Approval",
                        "Rejected",
                        "Dispatched",
                        "In Progress",
                        "Production Completed",
                        "Production Rejected",
                      ].includes(viewOrders.order_status)}
                      onClick={() => {
                        handleDispatch("openModal");
                      }}
                      style={{ background: "blue", color: "white" }}
                      id="ordertoproduction"
                      className="ordertoproduction"
                    >
                      Dispatch
                    </Button>
                  )
                : ""}
              <Modal
                title="Add Other Materials"
                open={isDispatchModalVisible}
                onCancel={handleDispatchCancel}
                footer={null}
                className="newAntModelContent"
                maskClosable={false}
                centered
                width={600}
              >
                <hr />
                <Form
                  form={form}
                  // onFinish={handleDispatch}
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 16 }}
                >
                  <div style={{ height: "400px" }}>
                    <div
                      style={{
                        height: "300px",
                        overflow: "hidden",
                        overflowY: "scroll",
                      }}
                    >
                      {rows.map((row, index) => (
                        <div key={row.id}>
                          <Row align="middle">
                            <Col span={10}>
                              <Form.Item
                                label="Select Material"
                                name={`store_id_${index}`}
                              >
                                <Select
                                  showSearch
                                  allowClear
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  placeholder="Select"
                                  value={row.store_id}
                                  onChange={(value) =>
                                    handleOtherMaterialChange(value, index)
                                  }
                                >
                                  {otherMaterials.map((loc) => (
                                    <Select.Option key={loc.id} value={loc.id}>
                                      {loc.other_material_name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={10}>
                              <Form.Item
                                label="Quantity"
                                id={`disquantity_${index}`}
                                className="diquantity"
                                validateStatus={"error"}
                                help={
                                  // row.a/vailableQuantity
                                  `Available - ${row.availableQuantity}`
                                  // : ""
                                }
                              >
                                <InputNumber
                                  min={0}
                                  // max={row.availableQuantity}
                                  changeOnWheel={false}
                                  controls={false}
                                  type="number"
                                  value={row.quantity}
                                  onChange={(value) =>
                                    handleOtherQuantityChange(value, index)
                                  }
                                  disabled={!row.isUnique} // Disable if store_id is not unique
                                />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              {index > 0 && (
                                <Form.Item>
                                  <Button
                                    style={{
                                      backgroundColor: "pink",
                                      borderRadius: "20px",
                                    }}
                                    type="danger"
                                    icon={<DeleteOutlined />}
                                    onClick={() => handleDeleteRow(index)}
                                  />
                                </Form.Item>
                              )}
                            </Col>
                          </Row>
                        </div>
                      ))}
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="button"
                          icon={<PlusOutlined />}
                          onClick={addRow}
                        ></Button>
                      </Form.Item>
                    </div>
                    <div style={{ height: "70px", marginTop: "5px" }}>
                      <Row align="middle">
                        <Col span={18}>
                          <Form.Item
                            label="Dispatch Remarks"
                            name="dispatch_remarks"
                          >
                            <Input.TextArea />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <div style={{ textAlign: "left" }}>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="button"
                          style={{ backgroundColor: "green" }}
                          onClick={handleDispatch}
                        >
                          Move to Dispatch
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                </Form>
              </Modal>
              &nbsp;&nbsp;
              {(harnetRoleAccess && harnetRoleAccess.includes("16")) ||
              (userRoleHarnet && userRoleHarnet === "1")
                ? ![
                    "Dispatched",
                    "In Progress",
                    "Rejected",
                    "Approved",
                  ].includes(viewOrders.order_status) && (
                    <Button
                      type="primary"
                      disabled={[
                        "Dispatched",
                        "In Progress",
                        "Rejected",
                        "Approved",
                      ].includes(viewOrders.order_status)}
                      style={{ background: "brown", color: "white" }}
                      id="ordertoproduction"
                      className="ordertoproduction"
                      onClick={handleReject}
                    >
                      Reject
                    </Button>
                  )
                : ""}
              <Modal
                title="Reject Order"
                open={isRejectModalVisible}
                onCancel={handleNewCancel}
                footer={null}
                className="newAntModelContent"
                maskClosable={false}
              >
                <Form onFinish={handleRejectSubmit}>
                  <Form.Item label="Reject Reason" name="rejected_reason">
                    <Input.TextArea />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
              &nbsp;&nbsp;
              <Modal
                title="Move to Production"
                open={isMoveToProduction}
                onCancel={handleOtherMatCancel}
                footer={null}
                className="newAntModelContent"
                maskClosable={false}
                width={300}
              >
                <Form onFinish={handleMoveToProduction}>
                  <div
                    style={{
                      color: "red",
                      fontSize: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Available Stock : {AvailableQuantityForProduction}
                  </div>
                  <Form.Item
                    label="Quantity for Production"
                    // name="production_quantity"
                  >
                    <InputNumber
                      min={0}
                      // max={row.availableQuantity}
                      changeOnWheel={false}
                      controls={false}
                      type="number"
                      autoFocus={true}
                      value={otherMatInput}
                      onChange={(e) => setOtherMatInput(e)}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      confirm
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
              {(harnetRoleAccess && harnetRoleAccess.includes("17")) ||
              (userRoleHarnet && userRoleHarnet === "1")
                ? ![
                    "Rejected",
                    "Dispatched",
                    "Approved",
                    "In Progress",
                    "Production Completed",
                  ].includes(viewOrders.order_status) && (
                    <Button
                      type="primary"
                      disabled={[
                        "Rejected",
                        "Dispatched",
                        "Approved",
                        "In Progress",
                        "Production Completed",
                      ].includes(viewOrders.order_status)}
                      style={{ background: "orange", color: "white" }}
                      onClick={() => handleMoveToProduction("open")}
                    >
                      Move to Production
                    </Button>
                  )
                : ""}
            </Form.Item>
          )}

      {viewOrders ? null : (
        <Form.Item wrapperCol={{ offset: 10, span: 50 }}>
          <Button
            type="primary"
            htmlType="submit"
            id="locationsubmit"
            className="locationsubmit"
          >
            {editOrders ? "Update" : "Place Order"}
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};
export default OrderForm;
