import { useEffect, useRef, useState } from "react";
import { ReadRecord } from "../../Service/API/ApiService";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Button, Table, DatePicker, Flex, Spin, Card, theme } from "antd";
import {
  LoadingOutlined,
  ExportOutlined,
  SearchOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import formatDate from "../../Components/formatDate";
import logo from "../../Assests/harnetlogo.png";
const headers = [
  { label: "Sl.No", key: "sl_no" },
  { label: "Stock In Date", key: "CreatedDate" },
  { label: "Jumbo", key: "type_name" },
  { label: "Jumbo Serial No", key: "jumbo_serial_no" },
  { label: "Material", key: "material_name" },
  { label: "Type", key: "jumbo_type_name" },
  { label: "Measurements", key: "length" },
  { label: "Stock Quantity", key: "quantity" },
  { label: "Jumbo Current Status", key: "jumbo_status" },
];

function StocksReport() {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const [data, setData] = useState([]);
  const [downloadedData, setDownloadedData] = useState([]);
  const csvDownloadRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [jumboOptions, setJumboOptions] = useState([]);
  const [materialOptions, setMaterialOptions] = useState([]);
  const [StoreOptions, setStoreOptions] = useState([]);
  const [OrderStatusQuery, setOrderStatusQuery] = useState("");
  const { RangePicker } = DatePicker;
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const exportPdf = useRef();
  useEffect(() => {
    fetchJumboOptions();
    fetchMaterialOptions();
    fetchStoreOptions();
    const nowIST = dayjs().tz("Asia/Kolkata");
    const startOfMonth = nowIST.startOf("month");
    const endOfMonth = nowIST.endOf("month");

    console.log("startOfMonth", startOfMonth);
    setFromDate(startOfMonth);
    setToDate(endOfMonth);
    // fetchData(formatDate(startOfMonth), formatDate(endOfMonth), radioutton);
  }, []);
  const fetchStoreOptions = async () => {
    try {
      const response = await ReadRecord(
        `SELECT distinct concat(store_width, " x ", store_length) as store_size FROM store WHERE status = 1 and ActiveStatus = 1`
      );
      setStoreOptions(response.data[0]?.rows || []);
    } catch (error) {
      console.error("Error fetching Store options:", error);
    }
  };
  const fetchMaterialOptions = async () => {
    try {
      const response = await ReadRecord(
        `SELECT id,material_name FROM material_master WHERE status = 1 and ActiveStatus = 1`
      );
      setMaterialOptions(response.data[0]?.rows || []);
    } catch (error) {
      console.error("Error fetching Material options:", error);
    }
  };
  const fetchJumboOptions = async () => {
    try {
      const response = await ReadRecord(
        `SELECT id,jumbo_type FROM jumbo_master WHERE status = 1 and ActiveStatus = 1 order by id desc;`
      );
      setJumboOptions(response.data[0]?.rows || []);
    } catch (error) {
      console.error("Error fetching Jumbo options:", error);
    }
  };
  useEffect(() => {
    if (fromDate && toDate) {
      fetchData(fromDate, toDate, OrderStatusQuery);
      // fetchDataToDownload(fromDate, toDate);
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: 1,
        pageSize: pagination.pageSize,
      }));
    } else {
      setData([]);
      setIsLoading(false);
    }
  }, [fromDate, toDate]);

  const fetchDataToDownloadPDF = () => {
    console.log("downloading data", data);
    setIsLoading(true);
    if (fromDate && toDate) {
      const doc = new jsPDF();
      doc.setFontSize(18); // Set font size for the title
      doc.text("Harnet India Private Ltd.,", 80, 12);
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 139); // Dark blue color
      doc.text("JUMBO STOCK REPORT :", 15, 15);
      const logoImg = logo;
      doc.addImage(
        logoImg,
        "PNG",
        doc.internal.pageSize.getWidth() - 35,
        3,
        20,
        15
      );

      // Define the styles for the table
      const tableStyles = {
        theme: "grid",
        styles: {
          fontSize: 8, // Adjust the font size here
          halign: "center",
          cellWidth: "wrap",
        },
        margin: { top: 20 },
      };

      // Adjusting the columns to include dataKey and styling
      const tableColumns = columns.map((col) => ({
        ...col,
        dataKey: col.dataIndex,
      }));
      const dataWithSerialNumbers = filteredData.map((row, index) => ({
        ...row,
        serial: index + 1,
        jumbo_type_name:
          row.jumbo_type_name == 1
            ? "Label"
            : row.jumbo_type_name == 2
            ? "Ribbon"
            : "",
      }));
      // Generate the table with the provided styles
      doc.autoTable(tableColumns, dataWithSerialNumbers, tableStyles);

      // Save the PDF
      doc.save(`Jumbo_Stock_Report_${formatDate(new Date())}.pdf`);
      setIsLoading(false);
    } else {
      setDownloadedData([]);
    }
  };

  const fetchDataToDownload = (fromDate = null, toDate = null, query = "") => {
    setIsLoading(true);
    let dateFilter = "";
    if (fromDate && toDate) {
      dateFilter = `AND t1.CreatedDate BETWEEN '${formatDate(
        fromDate
      )} 00:00:00' AND '${formatDate(toDate)} 23:59:59'`;
      ReadRecord(`SELECT t1.*, t2.jumbo_serial_no,t3.jumbo_type AS type_name,t3.type AS jumbo_type_name ,t1.store_length AS length,t1.store_width AS width,t4.material_name,t3.type AS type_name2 
      FROM store t1
       join jumbo_store t2 ON t1.type_id=t2.id join jumbo_master t3 ON t2.jumbo_master_id=t3.id join material_master t4 ON t3.material_id=t4.id
      WHERE t1.status = 1 AND t1.ActiveStatus = 1 AND
       t1.type = 1 
       ${dateFilter} ${query} ORDER BY t1.id desc`)
        .then((res) => {
          console.log("Fetched data:", res);
          const modifiedData = res.data[0].rows.map((item, index) => {
            return {
              ...item,
              sl_no: index + 1, // Adding Sl.No.
              jumbo_type_name:
                item.jumbo_type_name == 1
                  ? "Label"
                  : item.jumbo_type_name == 2
                  ? "Ribbon"
                  : "", // Add size based on the conditions
            };
          });

          setDownloadedData(modifiedData);
          setTimeout(() => {
            csvDownloadRef.current.link.click();
          }, 500);
        })
        .catch((error) => alert("Error happened"))
        .finally(() => setIsLoading(false));
    } else {
      setDownloadedData([]);
    }
  };

  const fetchData = (fromDate = null, toDate = null, query = "") => {
    setIsLoading(true);
    let dateFilter = "";
    if (fromDate && toDate) {
      dateFilter = `AND t1.CreatedDate BETWEEN '${formatDate(
        fromDate
      )} 00:00:00' AND '${formatDate(toDate)} 23:59:59'`;
      ReadRecord(`SELECT t1.*, t2.jumbo_serial_no,t3.jumbo_type AS type_name,t3.type AS jumbo_type_name ,t1.store_length AS length,t1.store_width AS width,t4.material_name,t3.type AS type_name2 
      FROM store t1
       join jumbo_store t2 ON t1.type_id=t2.id join jumbo_master t3 ON t2.jumbo_master_id=t3.id join material_master t4 ON t3.material_id=t4.id
      WHERE t1.status = 1 AND t1.ActiveStatus = 1 AND
       t1.type = 1 
       ${dateFilter} ${query} ORDER BY FIELD(t1.jumbo_status, 'Available', 'In Production', 'No Stock'), t1.id desc`)
        .then((res) => {
          console.log("Fetched data:", res);
          setData(res.data[0].rows);
        })
        .catch((error) => alert("Error happened"))
        .finally(() => setIsLoading(false));
    }
  };

  const columns = [
    {
      title: "Sl.No.",
      dataIndex: "serial",
      key: "serial",
      align: "center",
      width: 10,
      render: (text, record, index) =>
        index + (pagination.current - 1) * pagination.pageSize + 1,
    },
    {
      title: "Stock In Date",
      dataIndex: "CreatedDate",
      key: "CreatedDate",
      align: "center",
      width: 20,
    },
    {
      title: "Jumbo",
      dataIndex: "type_name",
      key: "type_name",
      align: "center",
      width: 10,
      filters: jumboOptions.map((item) => ({
        text: item.jumbo_type,
        value: item.id,
      })),
      filteredValue: filteredInfo.type_name || null,
    },

    {
      title: "Jumbo Serial No",
      dataIndex: "jumbo_serial_no",
      key: "order1",
      align: "center",
      width: 20,
    },
    {
      title: "Material",
      dataIndex: "material_name",
      key: "material_name",
      align: "center",
      width: 20,
      filters: materialOptions.map((item) => ({
        text: item.material_name,
        value: item.material_name,
      })),
      filteredValue: filteredInfo.material_name || null,
      onFilter: (value, record) => record.material_name === value,
    },
    {
      title: "Type",
      dataIndex: "jumbo_type_name",
      key: "jumbo_type_name",
      align: "center",
      width: 10,
      filters: [
        { text: "Label", value: "1" },
        { text: "Ribbon", value: "2" },
      ],
      filteredValue: filteredInfo.jumbo_type_name || null,
      onFilter: (value, record) => record.jumbo_type_name === value,
      render: (type) => (
        <span style={{ fontSize: "10px" }}>
          {type == 1 ? "Label" : type == 2 ? "Ribbon" : ""}
        </span>
      ),
    },
    {
      title: "Measurements",
      dataIndex: "length",
      key: "Measurements",
      align: "center",
      width: 50,
      filters: StoreOptions.map((item) => ({
        text: item.store_size,
        value: item.store_size,
      })),
      filteredValue: filteredInfo.Measurements || null,
      // onFilter: (value, record) => record.store_size === value,
      render: (text, record) => (
        <span style={{ fontSize: "10px" }}>
          {record.store_width + " x " + record.store_length + "  (m)"}
        </span>
      ),
    },
    {
      title: "Stock Quantity",
      dataIndex: "quantity",
      key: "orderI3",
      align: "center",
      width: 20,
    },
    {
      title: "Jumbo Current Status",
      dataIndex: "jumbo_status",
      key: "jumbo_status",
      align: "center",
      width: 100,
      filters: [
        { text: "In Production", value: "In Production" },
        { text: "No Stock", value: "No Stock" },
        { text: "Available", value: "Available" },
      ],
      filteredValue: filteredInfo.jumbo_status || null,
      onFilter: (value, record) => record.jumbo_status === value,
    },
  ];

  const filteredData = filterStatus
    ? data.filter((item) => item.order_status === filterStatus)
    : data;

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      console.log("From Date:", dates[0]);
      console.log("To Date:", dates[1]);
      setFromDate(dates[0]);
      setToDate(dates[1]);
      // fetchData(dates[0], dates[1]);
      //fetchDataToDownload(dates[0], dates[1]);
    } else {
      console.log("Dates Cleared");
      setFromDate(null);
      setToDate(null);
      fetchData(null, null, OrderStatusQuery);
    }
  };
  const handleTableChange = async (pagination, filters) => {
    console.log("filters", filters);
    let query = "";
    const filterClauses = [];
    if (
      filters?.Measurements != null ||
      filters?.jumbo_status != null ||
      filters?.jumbo_type_name != null ||
      filters?.material_name != null ||
      filters?.type_name != null
    ) {
      setFilteredInfo(filters);
    } else {
      setFilteredInfo({});
    }
    // Check and add clause for jumbo_status
    if (filters.jumbo_status && filters.jumbo_status.length > 0) {
      const orderStatusInClause = filters.jumbo_status
        .filter((item) => item) // Filter out empty or invalid values
        .map((item) => `'${item}'`)
        .join(", ");
      if (orderStatusInClause) {
        filterClauses.push(`t1.jumbo_status IN (${orderStatusInClause})`);
      }
    }

    // Check and add clause for material_name
    if (filters.material_name && filters.material_name.length > 0) {
      const materialNameInClause = filters.material_name
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (materialNameInClause) {
        filterClauses.push(`t4.material_name IN (${materialNameInClause})`);
      }
    }

    // Check and add clause for jumbo_type_name
    if (filters.jumbo_type_name && filters.jumbo_type_name.length > 0) {
      const typeNameInClause = filters.jumbo_type_name
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (typeNameInClause) {
        filterClauses.push(`t3.type IN (${typeNameInClause})`);
      }
    }

    // Check and add clause for Measurements
    if (filters.Measurements && filters.Measurements.length > 0) {
      const MeasurementsInClause = filters.Measurements.filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (MeasurementsInClause) {
        filterClauses.push(
          `concat(t1.store_width, " x ", t1.store_length) IN (${MeasurementsInClause})`
        );
      }
    }
    if (filters.type_name && filters.type_name.length > 0) {
      const typeNameInClause = filters.type_name
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");

      if (typeNameInClause) {
        await ReadRecord(
          `SELECT id FROM jumbo_store WHERE jumbo_master_id IN (${typeNameInClause});`
        ).then((response) => {
          const ids = response.data[0].rows
            .map((row) => `'${row.id}'`)
            .join(", ");
          if (ids) {
            filterClauses.push(`t1.type_id IN (${ids})`);
          }
        });
      }
    }

    // Join all filter clauses with AND
    if (filterClauses.length > 0) {
      query = `AND ${filterClauses.join(" AND ")}`;
    }

    // Update the query state
    setOrderStatusQuery(query);

    // Trigger the API check with pagination and filters
    return fetchData(formatDate(fromDate), formatDate(toDate), query);
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p
          style={{
            color: "#333",
            marginRight: "auto",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          Jumbo Stock
        </p>
        <RangePicker
          value={[fromDate, toDate]}
          onChange={handleDateChange}
          style={{ width: 300 }}
        />
        <CSVLink
          headers={headers}
          data={downloadedData}
          filename={`Jumbo_Stock_Report_${formatDate(new Date())}.csv`}
          className="hidden"
          ref={csvDownloadRef}
          target="_blank"
        />
        &nbsp;
        <Button
          disabled={data?.length == 0}
          style={{ backgroundColor: "rgba(175, 253, 111, 0.26)" }}
          icon={<ExportOutlined style={{ color: "green", fontSize: 24 }} />}
          onClick={() =>
            fetchDataToDownload(fromDate, toDate, OrderStatusQuery)
          }
        >
          Export to Excel
        </Button>
        &nbsp;
        <Button
          disabled={data?.length == 0}
          style={{ backgroundColor: "rgba(255, 0, 0, 0.1)" }}
          icon={<PrinterOutlined style={{ color: "brown", fontSize: 24 }} />}
          onClick={fetchDataToDownloadPDF}
        >
          Export to PDF
        </Button>
      </div>
      {isLoading ? (
        <LoadingOutlined style={{ marginTop: 20 }}>
          Loading report...
        </LoadingOutlined>
      ) : (
        <Table
          onChange={handleTableChange}
          className="custom-table custom-table-th"
          rowClassName={(record, index) =>
            index % 2 === 1 ? "even-row" : "odd-row"
          }
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            // onChange: (page, pageSize) => {
            //   setPagination((prevPagination) => ({
            //     ...prevPagination,
            //     current: page,
            //     pageSize: pageSize,
            //   }));
            // },
            total: pagination.total,
          }}
          size="small"
          bordered
          id="orders-table"
          dataSource={filteredData}
          columns={columns}
          locale={{
            emptyText: isLoading ? (
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            ) : (
              <Card style={{ background: "rgb(240, 240, 240)" }}>
                <SearchOutlined />
                &nbsp; No data available
              </Card>
            ),
          }}
        />
      )}
    </div>
  );
}

export default StocksReport;
