import React, { useState } from "react";
import { TinyColor } from "@ctrl/tinycolor";
import {
  Button,
  Form,
  Input,
  Card,
  Grid,
  Typography,
  ConfigProvider,
  Space,
} from "antd";
import leftImage from "../../Assests/inventory2.png";
import logo from "../../Assests/harnetlogo.png";
import newImage from "../../Assests/barcode-label-ribbon.png";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { ReadRecord } from "../../Service/API/ApiService";

const SignIn = () => {
  const [errorMessage, setErrorMessage] = useState(null);

  const onFinish = (values) => {
    ReadRecord(
      `SELECT * from user_master where BINARY username='${values.username}' and BINARY password='${values.password}' and status=1 `
    ).then((res) => {
      if (res.data[0].count > 0) {
        const roleAccessIds = res.data[0].rows[0]?.role_access_id?.split(",");

        localStorage.setItem("loginHarnet", true);
        localStorage.setItem("usernameHarnet", res.data[0].rows[0].username);
        localStorage.setItem("userID", res.data[0].rows[0].id);
        localStorage.setItem("userRoleHarnet", res.data[0].rows[0].role_id);
        localStorage.setItem("harnetRoleAccess", JSON.stringify(roleAccessIds));
        window.history.pushState({}, "", "/");
        window.location.reload();
      } else {
        setErrorMessage("Invalid user credentials!");
      }
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="container">
      <div className="left">
        <div className="image-container">
          <img src={leftImage} style={{ width: "-webkit-fill-available" }} />
        </div>
      </div>

      <div className="right">
        <div className="form-container">
          <div className="form-title">
            <div className="svg-signin">
              <img src={logo}></img>
              {/* <svg
                width="22"
                height="32"
                viewBox="0 0 55 81"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="50" cy="50" r="40" fill="red" />
              </svg> */}
              {/* <h1 className="harnet" >
                <b> Harnet</b>
              </h1> */}
            </div>
          </div>
          <h1>Login</h1>

          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            id="userloginform"
            className="newform"
          >
            <Form.Item
              name="username"
              className="ant-form-item-label"
              id="user1"
              rules={[
                {
                  required: true,
                  message: "Please enter your username!",
                },
              ]}
            >
              <Input
                className="antd-input"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
              />
            </Form.Item>

            <Form.Item
              name="password"
              id="userpassword2"
              className="ant-form-item-label"
              rules={[
                {
                  required: true,
                  message: "Please enter your password!",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 5,
                span: 16,
              }}
            >
              <Button
                id="userloginbutton"
                type="primary"
                htmlType="submit"
                Id="loginbutton"
                className="loginbutton"
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default SignIn;
